import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { ApprovaldashboardRoutingModule } from './approvaldashboard-routing.module';
import { ApprovaldashboardComponent } from './approvaldashboard/approvaldashboard.component';
import { AddressstripeModule } from '../addressstripe/addressstripe.module';
import { DialogModule } from 'primeng/dialog';
import { TabViewModule } from 'primeng/tabview';
import { MatExpansionModule } from '@angular/material/expansion';
import { OrderStatusPipe } from '../../pipes/order-status.pipe';
import { ProductStatusPipe } from '../../pipes/product-status.pipe';
import { AccordionModule } from 'primeng/accordion';
import { DropdownModule } from 'primeng/dropdown';
import { FormsModule } from '@angular/forms';
import { TableModule } from 'primeng/table';
import { CheckboxModule } from 'primeng/checkbox';
import { RadioButtonModule } from 'primeng/radiobutton';
import { TimezoneModule } from 'src/app/pipes/timezonepipe/timezone/timezone.module';
import { LazyLoadImageModule } from 'ng-lazyload-image';
import { MailapprovalComponent } from './mailapproval/mailapproval.component';
@NgModule({
  declarations: [
    ApprovaldashboardComponent,
    OrderStatusPipe,
    ProductStatusPipe,
    MailapprovalComponent
  ],
  imports: [
    CommonModule,
    DialogModule,
    TabViewModule,
    MatExpansionModule,
    RadioButtonModule,
    ApprovaldashboardRoutingModule,
    AddressstripeModule,
    AccordionModule,
    CheckboxModule,
    TableModule,
    DropdownModule,
    FormsModule,
    TimezoneModule,
    LazyLoadImageModule
  ],
  exports: [ApprovaldashboardComponent]
})
export class ApprovaldashboardModule { }

import { Component, OnInit } from '@angular/core';
import { MenuItem, SelectItem } from 'primeng/api';
import { NavbarService } from '../service/navbar.service';
import { Category, MenuList, Navbar } from '../model/navbar';
import { CommonserviceService } from '../../../common/services/commonservice.service';
import * as _ from 'lodash';
import { Router } from '@angular/router';
import { Selectedstorelocations } from 'src/app/Models/selectedstorelocations.model';
import { Carousel } from 'primeng/carousel';
import { Subscription } from 'rxjs';
@Component({
  selector: 'app-navbar',
  templateUrl: './navbar.component.html',
  styleUrls: ['./navbar.component.scss']
})


export class NavbarComponent implements OnInit {
  menuItems: MenuList[];
  subscription: Subscription = new Subscription();
  responsiveOptions;
  constructor(private svc: NavbarService, private cmnSvc: CommonserviceService, private route: Router) {

    const updateNavbar = this.cmnSvc.menuItems.subscribe((updateNav: any) => {
      this.menuItems = _.map(updateNav[1], (val) => {
        if (val.CategoryName == "Store Services")
          val.isShow = this.cmnSvc.checkRole('storeservice')
        else
          val.isShow = true;
        return val;
      });
      var menuItemLength = 0;
      for (let i = 0; i < this.menuItems.length; i++) {
        menuItemLength = menuItemLength + this.menuItems[i].CategoryName.length;
      }
      if (menuItemLength > 130) {
        this.showCarousel = true;
      } else {
        this.showCarousel = false;
      }
      const cate = this.menuItems.find(a => a.CategoryName == "Office Supplies")
      if (cate)
        this.cmnSvc.officesupplyCatId = cate.CategoryId;
      this.cmnSvc.onloadNavbar.emit(this.menuItems);

    });
    this.subscription.add(updateNavbar);

    this.responsiveOptions = [
      {
        breakpoint: '1024px',
        numVisible: 5,
        numScroll: 2
      },
      {
        breakpoint: '1200px',
        numVisible: 6,
        numScroll: 4
      },
      {
        breakpoint: '1300px',
        numVisible: 7,
        numScroll: 2
      }
    ];
    Carousel.prototype.changePageOnTouch = (e, diff) => { }
  }
  //menuItems: any;
  h: any;
  highlightedmenu: boolean;
  myorderitems: MenuItem[];
  cartCount: number;
  showConfirm: boolean;
  selectedstorelocations: Selectedstorelocations;
  locationitems: SelectItem[];
  username: string = this.cmnSvc.UserDetails ? this.cmnSvc.UserDetails.FirstName : "User";
  hasAppr: boolean = true;
  showCarousel: boolean = false;
  userMenuitems: MenuItem[];
  ngOnInit() {
    const lsub = this.cmnSvc.locationChangeEmitter.subscribe(() => {
      this.getmainmenulist();
    });
    this.cmnSvc.CartCount.subscribe((cnt: number) => {

      this.cartCount = cnt;
    });
    this.myorderitems = [
      { label: 'Order History', command: e => this.navigatetoOrder() },

      { label: 'Favorites', command: e => this.mywishlist() }
    ];
    if (this.cmnSvc.checkRole('report')) {
      this.myorderitems.push({ label: 'Report', command: e => this.naviGateToreport() });
    }
    this.userMenuitems = this.getMenuItemsForItem();

    this.selectedstorelocations = this.cmnSvc.selectedstorelocations;
    if (this.cmnSvc.UserDetails) {
      this.svc.getMenuList(this.cmnSvc.UserDetails.UserId, this.cmnSvc.selectedstorelocations.StoreNumber).subscribe(responseList => {
        const allCategories = responseList[0];
        this.menuItems = _.map(responseList[1], (val) => {
          if (val.CategoryName == "Store Services" )
            val.isShow = this.cmnSvc.checkRole('storeservice')
          else
            val.isShow = true;
          return val;
        });

        var menuItemLength = 0;
        for (let i = 0; i < this.menuItems.length;i++) {
            menuItemLength = menuItemLength + this.menuItems[i].CategoryName.length;
        }
        if (menuItemLength > 130) {
          this.showCarousel = true;
        } else {
          this.showCarousel = false;
        }
        const cate = this.menuItems.find(a => a.CategoryName == "Office Supplies")
        if (cate)
          this.cmnSvc.officesupplyCatId = cate.CategoryId;
        this.cmnSvc.onloadNavbar.emit(this.menuItems);
      });
      
    }
    this.locationitems = _.map(this.cmnSvc.userStoreLocation, (location) => {
      return {
        label: location.StoreNumber + ' ' + location.Description + '-'
          + location.Address1 + ' ' + location.City
          + ' ' + location.ZipCode
        , icon: 'fa fa-map-marker'
        , value: location
      };
    });
    if (this.cmnSvc.UserDetails) {
      this.getcartCount()
    }

  }
  onchange(event) {
    if (this.cartCount > 0)
      this.showConfirm = true;
    else {
      this.cmnSvc.selectedstorelocations = this.selectedstorelocations;
      this.hasAppr = this.selectedstorelocations.Approver ? true : false;
      this.route.navigateByUrl('/');
      this.cmnSvc.locationChangeEmitter.emit(true);
    }
  }
  navigate(val: Category) {

    this.highlightedmenu = true;
    this.clickEvent();
    this.cmnSvc.Category = val;
    this.route.navigate(['/item'], { queryParams: { categoryId: val.CategoryId, categoryName: val.CategoryName } });
  }
  status: boolean = false;
  clickEvent() {
    this.status = !this.status;
  }
  getcartCount() {
    const sub = this.svc.getCartCount(this.cmnSvc.UserDetails.UserId).subscribe((count: any) => {

      this.cartCount = count.CartCount;
    });

  }
  naviGateToreport() {
    this.route.navigateByUrl('/dashboardreport');
    this.clickEvent();
  }
  onConfirm() {
    this.svc.DeleteCartItem(this.cmnSvc.UserDetails.UserId).subscribe(() => {
      this.showConfirm = false;
      this.cmnSvc.locationChangeEmitter.emit(true);
      this.cmnSvc.selectedstorelocations = this.selectedstorelocations;
      this.route.navigateByUrl('/');
      this.cmnSvc.CartCount.next(0);
    });
  }
  onCancel() {
    this.showConfirm = false;
    this.selectedstorelocations = this.cmnSvc.selectedstorelocations;

  }
  getMenuItemsForItem(): MenuItem[] {

    if (this.cmnSvc.UserDetails) {
      const menu: MenuItem[] = [];
      if (this.cmnSvc.checkRole('dashboard')) {
        menu.push({ label: 'Dashboard', id: '2', command: e => this.naviGateToDashBoard() });
      }
      if (this.cmnSvc.checkRole('admin')) {
        menu.push({ label: 'Admin', id: '3', command: e => this.naviGateToAdmin() });
      }
      menu.push({ label: 'Sign Out', command: e => this.SignOut() });
      return menu;
    } else {

      return [
        // { label: 'My Acount', id: '1' },

        { label: 'Sign In', command: e => this.SignIn() },
        // { label: 'Sign Out', command: e => this.SignOut(e, context) }
      ];
    }
  }
  mywishlist() {
    this.route.navigate(['item'], { queryParams: { categoryId: -1, categoryName: 'Favorites' } });
    this.clickEvent();
  }
  navigatetoOrder(): void {
    this.cmnSvc.isreportapprovactive = false;
    this.route.navigateByUrl('/orderhistory');
    this.clickEvent();
  }
  naviGateToDashBoard() {
    this.cmnSvc.isreportapprovactive = false;
    this.route.navigateByUrl('/dashboard');
    this.clickEvent();
  }
  naviGateToAdmin() {
    this.route.navigateByUrl('/admin');
    this.clickEvent();
  }
  SignOut(): void {
    this.route.navigateByUrl('/logout');
    this.clickEvent();
    this.svc.logout().subscribe();
  }
  SignIn(): void {

  }
  getmainmenulist() {
    this.svc.getMenuList(this.cmnSvc.UserDetails.UserId, this.cmnSvc.selectedstorelocations.StoreNumber).subscribe(responseList => {
      const allCategories = responseList[0];
      this.menuItems = _.map(responseList[1], (val) => {
        if (val.CategoryName == "Store Services")
          val.isShow = this.cmnSvc.checkRole('storeservice')
        else
          val.isShow = true;
        return val;
      });
      const cate = this.menuItems.find(a => a.CategoryName == "Office Supplies")
      if (cate)
        this.cmnSvc.officesupplyCatId = cate.CategoryId;
      this.cmnSvc.onloadNavbar.emit(this.menuItems);
    });
  }
}

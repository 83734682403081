import { Component, OnInit, OnDestroy } from '@angular/core';
import { MenuItem, SelectItem } from 'primeng/api';

import { AutoCompleteModule } from 'primeng/autocomplete';
import { CommonserviceService } from '../../../common/services/commonservice.service';
import { HeaderService } from '../service/header.service';
import * as _ from 'lodash';
import { Selectedstorelocations } from '../../../Models/selectedstorelocations.model';
import { Subscription } from 'rxjs';
import { ActivatedRoute, Router, NavigationEnd } from '@angular/router';
import { filter, map } from 'rxjs/operators';
import { Fund } from '../../../Models/Fund';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss']
})
export class HeaderComponent implements OnInit, OnDestroy {
  display: boolean = false;
  user: string;
  cartCount: number;
  auth: string[];
  hasAppr: boolean = true;
  subscription: Subscription = new Subscription();
  showConfirm: boolean;
  menu: MenuItem[] = [];
  constructor(
    private cmnSvc: CommonserviceService
    , private svc: HeaderService
    , private route: Router, private activeRoute: ActivatedRoute) {

    if (this.cmnSvc.UserDetails) {
      this.user = this.cmnSvc.UserDetails.FirstName;

    } else {
      this.user = 'User';
    }
    if (this.cmnSvc.UserDetails) {
      this.getcartCount()
    }

    const subcartcount = this.cmnSvc.CartCount.subscribe((cnt: number) => {

      this.cartCount = cnt;
    });

    this.subscription.add(subcartcount);
    this.cmnSvc.ActiveStoreLocation.subscribe((data: any) => {
      this.locationitems = _.map(this.cmnSvc.userStoreLocation, (location) => {
        return {
          label: location.StoreNumber + ' ' + location.Description + '-'
            + location.Address1 + ' ' + location.City
            + ' ' + location.ZipCode
          , icon: 'fa fa-map-marker'
          , value: location
        };
      });

    });
  }


  locationitems: SelectItem[];
  myorderitems: MenuItem[];
  userMenuitems: MenuItem[];
  userdetails: MenuItem[];
  selectedstorelocations: Selectedstorelocations;
  searchdata: any;
  search: string = "";
  isShowSearch: boolean;
  fund: Fund[];

  ngOnInit() {

    this.selectedstorelocations = this.cmnSvc.selectedstorelocations;
    this.hasAppr = this.selectedstorelocations.Approver ? true : false;
    if (this.cmnSvc.UserDetails) {
      this.fund = this.cmnSvc.AllocatedFund;
      this.locationitems = _.map(this.cmnSvc.userStoreLocation, (location) => {
        return {
          label: location.StoreNumber + ' ' + location.Description + '-'
            + location.Address1 + ' ' + location.City
            + ' ' + location.ZipCode
          , icon: 'fa fa-map-marker'
          , value: location
        };
      });
    }
    this.userMenuitems = this.getMenuItemsForItem();

    this.myorderitems = [
      { label: 'Order History', command: e => this.navigate() },

      { label: 'Favorites', command: e => this.mywishlist() }
    ];
    if (this.cmnSvc.checkRole('report')) {
      this.myorderitems.push({ label: 'Report', command: e => this.naviGateToreport()});
    }
    this.route.events.pipe(filter((e): e is NavigationEnd => e instanceof NavigationEnd),
      map((e) => {
        const data = this.activeRoute.root.firstChild.snapshot.data['showSearch'];
        if (data)
          this.isShowSearch = true;
        else
          this.isShowSearch = false;
      })).subscribe();

  }
  showDialog() {
    this.display = true;
  }
  getcartCount() {
    const sub = this.svc.getCartCount(this.cmnSvc.UserDetails.UserId).subscribe((count: any) => {

      this.cartCount = count.CartCount;
    });
    this.subscription.add(sub);
  }

  getMenuItemsForItem(): MenuItem[] {

    if (this.cmnSvc.UserDetails) {
      const menu: MenuItem[] = [];
      if (this.cmnSvc.checkRole('dashboard')) {
        menu.push({ label: 'Dashboard', id: '2', command: e => this.naviGateToDashBoard() });
      }
      if (this.cmnSvc.checkRole('admin')) {
        menu.push({ label: 'Admin', id: '3', command: e => this.naviGateToAdmin() });
      }
      menu.push({ label: 'Sign Out', command: e => this.SignOut() });
      return menu;
    } else {

      return [
        // { label: 'My Acount', id: '1' },

        { label: 'Sign In', command: e => this.SignIn() },
        // { label: 'Sign Out', command: e => this.SignOut(e, context) }
      ];
    }
  }
  SignIn(): void {

  }
  mywishlist() {
    this.route.navigate(['item'], { queryParams: { categoryId: -1, categoryName: 'Favorites' } });
  }

  SearchFun(search) {
    this.cmnSvc.searchitem = search;
    this.route.navigate(['searchs'], { queryParams: { s: search } });
    this.search = '';
  }

  SignOut(): void {

    localStorage.clear();

    this.route.navigateByUrl('/logout');
    this.svc.logout().subscribe();
  }
  onchange(event) {
    if (this.cartCount > 0)
      this.showConfirm = true;
    else {
      this.cmnSvc.selectedstorelocations = this.selectedstorelocations;
      this.hasAppr = this.selectedstorelocations.Approver ? true : false;
      this.route.navigateByUrl('/');
      this.cmnSvc.locationChangeEmitter.emit(true);
    }

  }
  onConfirm() {
    this.svc.DeleteCartItem(this.cmnSvc.UserDetails.UserId).subscribe(() => {
      this.showConfirm = false;
      this.cmnSvc.locationChangeEmitter.emit(true);
      this.cmnSvc.selectedstorelocations = this.selectedstorelocations;
      this.hasAppr = this.selectedstorelocations.Approver ? true : false;
      this.route.navigateByUrl('/');
      this.cmnSvc.CartCount.next(0);
    });
  }
  onCancel() {
    this.showConfirm = false;
    this.selectedstorelocations = this.cmnSvc.selectedstorelocations;

  }
  ngOnDestroy() {

    this.subscription.unsubscribe();
  }

  navigate(): void {
    this.cmnSvc.isreportapprovactive = false;
    this.route.navigateByUrl('/orderhistory');
  }
  naviGateToDashBoard() {
    this.cmnSvc.isapprovaldashboardactive = false;
    this.route.navigateByUrl('/dashboard');
  }
  naviGateToreport() {
    this.route.navigateByUrl('/dashboardreport');
  }
  naviGateToAdmin() {
    this.route.navigateByUrl('/admin');
  }

  onKeydown(event) {
    if (event.key === "Enter") {
      if (this.search != "") {
        this.route.navigate(['searchs'], { queryParams: { s: this.search } });
        this.search = '';
      }
      else
        this.route.navigateByUrl('/');
    }
  }
}

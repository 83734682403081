<div *ngIf="hasrole;then AddToCart else GoToCart"></div>
<ng-template #AddToCart>
  <app-header class="fixed-top" *ngIf="!isLogout"></app-header>
  <app-navbar class="fixed-top top-126 z-down" *ngIf="!isLogout"></app-navbar>
  <router-outlet><app-loading></app-loading></router-outlet>
  <app-footer *ngIf="!isLogout"></app-footer>

  <p-toast position="bottom-center">
    <ng-template let-message pTemplate="message">
      <div class="p-flex p-flex-column" style="flex: 1">
        <p><b>{{message.summary}}</b></p>
          <p [innerHtml]="message.detail"></p>
       </div>
    </ng-template>
  </p-toast>
</ng-template>
  <ng-template #GoToCart>
    <div class="order-dialog">

      <p-dialog header="" [(visible)]="!hasrole" [modal]="true" [responsive]="true" [closable]="false" [closeOnEscape]="false" [style]="{width: '450px'}">
        <p>
          You must be a valid user to access.  Please contact Store Services for assistance
        </p>
        <p-footer class="btn-footer">
          <button (click)="exit()" class="btn main-btn mr-3">Ok</button>
        </p-footer>
      </p-dialog>
    </div>
  </ng-template>

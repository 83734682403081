import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';
@Injectable({
  providedIn: 'root'
})
export class LoadingService {

  private Loader = false;
  hasChanges: Subject<boolean> = new Subject<boolean>();
  loaderStatus: Subject<boolean> = new Subject<boolean>();
  get loader(): boolean {
    return this.Loader;
  }
  set loader(value) {
    this.Loader = value;
    this.loaderStatus.next(value);
  }
  startLoader() {
    this.loader = true;
  }
  stopLoader() {
    this.loader = false;
  }

}

export class Category {
  CategoryId: number;
  CategoryName: string;
}
export class MenuList {
  CompanyName: string;
  CategoryName: string;
  CompanyNameId: number;
  CategoryId: number;
}

export class Navbar {
  Title: string;
  CategoryId?: number;
  CompanyNameId?: number;
  Categories: Navbar[];
}



import { Component, OnInit, EventEmitter } from '@angular/core';
import { Router, NavigationEnd, ActivatedRoute } from '@angular/router';
import { filter, map } from 'rxjs/operators';
import { ApprovalDashboardService } from '../service/approval-dashboard.service';
import { OrderIdDetails } from '../model/approval.model';

@Component({
  selector: 'app-mailapproval',
  templateUrl: './mailapproval.component.html',
  styleUrls: ['./mailapproval.component.scss']
})
export class MailapprovalComponent implements OnInit {
  CartId: number;
  isBulk: number;
  orderiddetails: OrderIdDetails[];
  encPassword: string = "X@!Z12&*";

  constructor(private router: Router, private route: ActivatedRoute, private svc: ApprovalDashboardService) {
    this.router.events.pipe(filter((e): e is NavigationEnd => e instanceof NavigationEnd),
      map((e) => {
        const Params = this.route.queryParamMap.subscribe(params => {
          this.CartId = this.route.snapshot.params.CartId;
          this.isBulk = this.route.snapshot.params.isBulk;
       
          //this.svc.ismailapprove.emit(this.orderid);
        

          //this.svc.getorderiddetails(this.orderid).subscribe((data: any) => {
          //  this.orderiddetails = data;
          });
          //      var EncryptOrderid = CryptoJS.AES.encrypt(this.orderid, this.encPassword).toString();
          //      var DecryptOrderid =  CryptoJS.AES.decrypt(EncryptOrderid, this.encPassword.trim()).toString(CryptoJS.enc.Utf8);
        //});

      })).subscribe();
    
  }
  ngOnInit() {
  }

}

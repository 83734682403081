export class Bulkorder {

  public UserId: number;
  public ItemId: number;
  public VendorId: number;
  public StoreNumber: number;
  public ItemCount: number;
  public Price: number;
  public CategoryId: number;
  public ItemName: string;
  public ItemCode: string;
  public Description: string;
  public UnitOfMeasure: string;
  public Nature: string;
  public CategoryName: string;
  public CategoryCode: string;
  public GLAccountNo: string;
  public TaxPercent: number;
  public ischecked: boolean;
  public EstimateShippingCost: number;
  public TotalAmount: number;
  public CompanyName: string;
  public CartId: number;

  //mnupdate
  public PackSize: number;
}
export class BulkorderFunds {

  public TotalCartValue: number;
  public TaxPercent: number;
  public ShippingCost: number;
  public GrandTotal: number;
  
}

<section class="seg-footer">
  <div class="container">
    <div class="footlogo">
      <img src="../../../assets/images/seg-footerlogo.png" />
    </div>
    <!--<div class="footlinks">
      <a href="#">Home</a> <span>|</span><a href="#">About Us </a><span>|</span> <a href="#">Community</a> <span>|</span>  <a href="#">Media </a><span>|</span> <a href="#">Careers</a> <span>|</span> <a href="#">Contact Us </a>
    </div>-->
    <div class="footlinks">
      <!--<a href="#">© 2019 Southeastern Grocers</a> <span>|</span><a href="#">Privacy Policy </a><span>|</span> <a href="#">Accessibility Statement</a> <span>|</span>  <a href="#">Accessibility Statement </a><span>|</span> <a href="#">E-Verify</a>-->
      <span class="copytext">© {{copyYear}} Southeastern Grocers</span> <span>|</span><a href="/" title="Home">Home</a>
    </div>
  </div>
</section>

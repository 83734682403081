
<div class="loader-screen-wrapper" *ngIf="loader">
  <div class="spinner-box">
    <i class="fa fa-spinner fa-spin fa-3x fa-fw"></i>
  </div>
</div>

<div class="loader-screen-wrapper" *ngIf="mailer">
  <div>
    <circle-progress [percent]="mailloader"
                     [radius]="70"
                     [outerStrokeWidth]="16"
                     [innerStrokeWidth]="8"
                     [outerStrokeColor]="'#37a657'"
                     [innerStrokeColor]="'#54d474'"
                     [animation]="true"
                     [animationDuration]="300"
                     [startFromZero]="false">

    </circle-progress>
  </div>
</div>

<!--<div class="order-dialog">
  <p-dialog header="" [(visible)]="showConf" [modal]="true" [responsive]="true" [style]="{width: '450px'}" [closable]="false" [closeOnEscape]="false" [draggable]="false">
    <p>
      Detected Changes in configuration and application needs to refresh.
    </p>
    <p-footer class="btn-footer">
      <button (click)="continue()" class="btn main-btn mr-3">Ok</button>
    </p-footer>
  </p-dialog>
</div>
-->

export enum PipeOptions {
  label = 'label',
  class = 'class',
  icon = 'icon'
}

export enum OrderStatusIcon {
  approved = 'fa fa-check',
  canceled = 'close',
  pending = 'fa fa-exclamation',
  onhold = 'fa fa-pause'
}

<section class="seg-approvaldashboard">
  <div class="col-12 px-lg-4">
    <h2 class="headline">Order Approval Dashboard</h2>

    <div class="custom-tab">
      <div class="col-lg-3 aproval-search p-0" *ngIf="role == 'SuperAdmin'">
        <input type="text" placeholder="Override Approval" [(ngModel)]="orderid" />
        <span class="fa fa-search search-icon" (click)="overrideapproval()"></span>
      </div>
      <p-tabView>
        <p-tabPanel header="Order Approval" *ngIf="checkeda">
          <div class="acc-table">
            <table class="w-100 max-table">
              <tr>
                <th>Order ID/Name</th>
                <th>Store Name</th>
                <th>Order Placed Date/Time</th>
                <th>Total Amount</th>
                <th>Submitter</th>
                <th>Approver</th>
                <!--<th>Status</th>-->
                <th>Workflow History</th>
                <th>Notes</th>
                <th>Action</th>
              </tr>
            </table>
          </div>

          <mat-accordion>
            <mat-expansion-panel class="main-accrdn max-table" (opened)="openGroup(list,i)" *ngFor="let list of approvallist ; let i = index;">

              <mat-expansion-panel-header *ngIf="list.Corp != 1">
                <mat-panel-title (click)="openGroup(list,i)">
                  <table class="w-100 list-table">
                    <tr class="{{ list.OrderId | orderStatus : 'class' }}">
                      <td data-label="Store ID/Name" class="{{list.Status}}">
                        <span class="store-bag">
                          <i class="fa fa-shopping-bag"
                             aria-hidden="true"></i>
                        </span>{{list.OrderId}}
                      </td>
                      <td data-label="Store Name">{{list.StoreInfo}}</td>
                      <td data-label="Order Placed Date/Time">{{(list.ModifiedDate | timezone : list.TimeZone) }}</td>
                      <td data-label="Total Amount">{{list.TotalCost | currency}}</td>
                      <td data-label="Submitter">{{list.Submitter}}</td>
                      <td data-label="Approver">{{list.ApprovedBy}}</td>
                      <!--<td data-label="Status" class="{{list.Status==='a'?'approved':'pending'}}">
                        <button class="store-info">
                          <i class="{{list.Status | orderStatus :'icon'}}"></i>
                          {{list.Status==='a'?'Approved':'Pending'| titlecase }}
                        </button>
                      </td>-->
                      <td data-label="Workflow History">

                        <button type="button" (click)="showWorkFlow()" class="workflow-btn">

                          <i class="fa fa-list" aria-hidden="true"></i> Workflow
                          History
                        </button>
                      </td>
                      <td data-label="Notes" class="notes"><button type="button" (click)="GetNote(list.CartId)"> <i class="fa fa-commenting" aria-hidden="true"></i> </button> </td>
                      <td data-label="Action">
                        <button type="button" (click)="Save(list,'a')" [disabled]="orderclicked" class="workflow-btn">
                          <i class="fa fa-list" aria-hidden="true"></i> Approve
                        </button>
                        <button type="button" (click)="Save(list,'d')" [disabled]="orderclicked" class="workflow-btn">
                          <i class="fa fa-list" aria-hidden="true"></i> Deny
                        </button>
                      </td>
                    </tr>
                  </table>
                </mat-panel-title>
              </mat-expansion-panel-header>
              <div class="row no-gutters product-info-list" *ngFor="let orderIdDetails of approvallist[i].orderIdDetails">
                <div class="col-12 col-lg-1 text-center"><img [defaultImage]="defaultImage" [lazyLoad]="orderIdDetails.ImageUrl?orderIdDetails.ImageUrl:'assets/img/no-image.jpg'" class="img-fluid" alt="order_img"></div>
                <div class="col-12 col-lg-9 d-flex align-items-center">
                  <div class="table-responsive">
                    <table class="w-100 product-item-table">
                      <tr>
                        <td>{{orderIdDetails.ItemName}}</td>
                        <td>Item #{{orderIdDetails.ItemId}}</td>
                        <td>{{orderIdDetails.UnitOfMeasure}} of {{orderIdDetails.ItemCount}}</td>
                        <td>By <span class="green-text">{{orderIdDetails.CompanyName}}</span></td>
                        <td *ngIf="orderIdDetails.OnEdit;then body else elseblock"></td>
                        <ng-template #body>
                          <td>
                            Quantity :
                            <select class="form-control w-auto" [(ngModel)]="orderIdDetails.selectedOption" (change)='onQuantitySelected(orderIdDetails)'>
                              <option *ngFor="let i of iterate(orderIdDetails.MaxAllowedToOrder)" value="{{i}}">{{i}}</option>
                            </select>
                          </td>
                        </ng-template>
                        <ng-template #elseblock>
                          <td>
                            Quantity :{{orderIdDetails.ItemCount}}
                          </td>
                        </ng-template>

                        <td>Price : {{(orderIdDetails.Price)| currency}}</td>
                        <td style="width:15%;">Amount : {{(orderIdDetails.PriceWithTax*orderIdDetails.ItemCount)| currency}}</td>
                        <td>

                          <button type="button" class="workflow-btn mr-2" (click)="onEdit(orderIdDetails)" [disabled]="orderIdDetails.OnEdit">
                            <i _ngcontent-moe-c2="" aria-hidden="true"
                               class="fa fa-pencil"></i>
                          </button>
                          <button type="button" class="workflow-btn" (click)="onReset(orderIdDetails)" [disabled]="!orderIdDetails.OnEdit">
                            <i _ngcontent-moe-c2="" aria-hidden="true"
                               class="fa fa-window-close"></i>
                          </button>

                          <!--<button type="button" (click)="Save(list,'d')" [disabled]="orderclicked">
                            <i class="fa fa-list" aria-hidden="true"></i> Deny
                          </button>-->
                        </td>
                      </tr>
                    </table>
                  </div>
                </div>
                <div class="col-12 col-lg-2 d-flex align-items-center">
                  <div class="table-responsive product-table-inner-icons">
                    <!--<p-radioButton value="a" [(ngModel)]="orderIdDetails.Status" class="mr-3"></p-radioButton>
                    <p-radioButton value="d" [(ngModel)]="orderIdDetails.Status"></p-radioButton>-->
                    <p-checkbox value="a" label="Approve" [(ngModel)]="orderIdDetails.ApprvdStatus" class="mr-3" (onChange)=" handle(orderIdDetails)"></p-checkbox>
                    <p-checkbox value="d" label="Deny" [(ngModel)]="orderIdDetails.ApprvdStatus" (onChange)=" handle(orderIdDetails)"></p-checkbox>

                  </div>
                </div>
              </div>
              <div class="notes-area pt-2 pb-2 ">

                <div class="w-100 d-lg-flex align-items-center justify-content-end">
                  <!--<input type="text"  [(ngModel)]="comment">-->
                  <textarea rows="4" cols="50" class="form-control" placeholder="Notes" [(ngModel)]="comment">
                  </textarea>
                  <button type="button" class="small-btn black-btn" (click)="AddNote(list.CartId)">
                    <i _ngcontent-moe-c2="" aria-hidden="true"
                       class="fa fa-pencil"></i> Add Note
                  </button>
                  <button type="button" class="small-btn black-btn" (click)="Submit(approvallist[i].orderIdDetails)">
                    <i _ngcontent-moe-c2="" aria-hidden="true"
                       class="fa fa-pencil"></i> Submit
                  </button>

                </div>
              </div>
            </mat-expansion-panel>
          </mat-accordion>





        </p-tabPanel>
        <!--<div *ngIf="checkedb">-->
          <p-tabPanel header="Bulk Order Approval" *ngIf="checkedb === checkeda">
            <div class="acc-table">
              <table class="w-100 max-table">
                <tr>
                  <th>Order ID/Name</th>
                  <th>Store Name</th>
                  <th>Order Placed Date/Time</th>
                  <th>Total Amount</th>
                  <th>Submitter</th>
                  <th>Approver</th>
                  <!--<th>Status</th>-->
                  <th>Workflow History</th>
                  <th>Notes</th>
                  <th>Action</th>
                </tr>
              </table>
            </div>

            <mat-accordion>
              <mat-expansion-panel class="main-accrdn max-table" (opened)="openGroup(list,i)" *ngFor="let list of approvallist ; let i = index;">

                <mat-expansion-panel-header *ngIf="list.Corp == 1">
                  <mat-panel-title (click)="openGroup(list,i)">
                    <table class="w-100 list-table">
                      <tr class="{{ list.OrderId | orderStatus : 'class' }}">
                        <td data-label="Store ID/Name" class="{{list.Status}}">
                          <span class="store-bag">
                            <i class="fa fa-shopping-bag"
                               aria-hidden="true"></i>
                          </span>{{list.OrderId}}
                        </td>
                        <td data-label="Store Name">{{list.StoreInfo}}</td>
                        <td data-label="Order Placed Date/Time">{{(list.ModifiedDate | timezone : list.TimeZone) }}</td>
                        <td data-label="Total Amount">{{list.TotalCost | currency}}</td>
                        <td data-label="Submitter">{{list.Submitter}}</td>
                        <td data-label="Approver">{{list.ApprovedBy}}</td>
                        <!--<td data-label="Status" class="{{list.Status==='a'?'approved':'pending'}}">
                          <button class="store-info">
                            <i class="{{list.Status | orderStatus :'icon'}}"></i>
                            {{list.Status==='a'?'Approved':'Pending'| titlecase }}
                          </button>
                        </td>-->
                        <td data-label="Workflow History">

                          <button type="button" (click)="showWorkFlow()" class="workflow-btn">

                            <i class="fa fa-list" aria-hidden="true"></i> Workflow
                            History
                          </button>
                        </td>
                        <td data-label="Notes" class="notes"><button type="button" (click)="GetNote(list.CartId)"> <i class="fa fa-commenting" aria-hidden="true"></i> </button> </td>
                        <td data-label="Action">
                          <button type="button" (click)="Save(list,'a')" [disabled]="orderclicked" class="workflow-btn">
                            <i class="fa fa-list" aria-hidden="true"></i> Approve
                          </button>
                          <button type="button" (click)="Save(list,'d')" [disabled]="orderclicked" class="workflow-btn">
                            <i class="fa fa-list" aria-hidden="true"></i> Deny
                          </button>
                        </td>
                      </tr>
                    </table>
                  </mat-panel-title>
                </mat-expansion-panel-header>
                <div class="row no-gutters product-info-list" *ngFor="let orderIdDetails of approvallist[i].orderIdDetails">
                  <div class="col-12 col-lg-1 text-center"><img [defaultImage]="defaultImage" [lazyLoad]="orderIdDetails.ImageUrl?orderIdDetails.ImageUrl:'assets/img/no-image.jpg'" class="img-fluid" alt="order_img"></div>
                  <div class="col-12 col-lg-9 d-flex align-items-center">
                    <div class="table-responsive">
                      <table class="w-100 product-item-table">
                        <tr>
                          <td>{{orderIdDetails.ItemName}}</td>
                          <td>Item #{{orderIdDetails.ItemId}}</td>
                          <td>{{orderIdDetails.UnitOfMeasure}} of {{orderIdDetails.ItemCount}}</td>
                          <td>By <span class="green-text">{{orderIdDetails.CompanyName}}</span></td>
                          <td *ngIf="orderIdDetails.OnEdit;then body else elseblock"></td>
                          <ng-template #body>
                            <td>
                              Quantity :
                              <select class="form-control w-auto" [(ngModel)]="orderIdDetails.selectedOption" (change)='onQuantitySelected(orderIdDetails)'>
                                <option *ngFor="let i of iterate(orderIdDetails.MaxAllowedToOrder)" value="{{i}}">{{i}}</option>
                              </select>
                            </td>
                          </ng-template>
                          <ng-template #elseblock>
                            <td>
                              Quantity : {{orderIdDetails.ItemCount}}
                            </td>
                          </ng-template>


                          <!--<td>Price : {{(orderIdDetails.Price*orderIdDetails.ItemCount)| currency}}</td>-->
                          <td>Price : {{(orderIdDetails.Price)| currency}}</td>
                          <td style="width:15%;">Amount: {{(orderIdDetails.PriceWithTax*orderIdDetails.ItemCount)| currency}}</td>

                          <td>Store #: {{orderIdDetails.StoreNumber}}</td>
                          <td>

                            <button type="button" class="workflow-btn mr-2" (click)="onEdit(orderIdDetails)" [disabled]="orderIdDetails.OnEdit">
                              <i _ngcontent-moe-c2="" aria-hidden="true"
                                 class="fa fa-pencil"></i>
                            </button>
                            <button type="button" class="workflow-btn" (click)="onReset(orderIdDetails)" [disabled]="!orderIdDetails.OnEdit">
                              <i _ngcontent-moe-c2="" aria-hidden="true"
                                 class="fa fa-window-close"></i>
                            </button>

                            <!--<button type="button" (click)="Save(list,'d')" [disabled]="orderclicked">
                              <i class="fa fa-list" aria-hidden="true"></i> Deny
                            </button>-->
                          </td>
                        </tr>
                      </table>
                    </div>
                  </div>
                  <div class="col-12 col-lg-2 d-flex align-items-center">
                    <div class="table-responsive product-table-inner-icons">
                      <!--<p-radioButton value="a" [(ngModel)]="orderIdDetails.Status" class="mr-3"></p-radioButton>
                      <p-radioButton value="d" [(ngModel)]="orderIdDetails.Status"></p-radioButton>-->
                      <p-checkbox value="a" label="Approve" [(ngModel)]="orderIdDetails.ApprvdStatus" class="mr-3" (onChange)=" handle(orderIdDetails)"></p-checkbox>
                      <p-checkbox value="d" label="Deny" [(ngModel)]="orderIdDetails.ApprvdStatus" (onChange)=" handle(orderIdDetails)"></p-checkbox>

                    </div>
                  </div>
                </div>
                <div class="notes-area pt-2 pb-2 ">

                  <div class="w-100 d-lg-flex align-items-center justify-content-end">
                    <!--<input type="text"  [(ngModel)]="comment">-->
                    <textarea rows="4" cols="50" class="form-control" placeholder="Notes" [(ngModel)]="comment">
                  </textarea>
                    <button type="button" class="small-btn black-btn" (click)="AddNote(list.CartId)">
                      <i _ngcontent-moe-c2="" aria-hidden="true"
                         class="fa fa-pencil"></i> Add Note
                    </button>
                    <button type="button" class="small-btn black-btn" (click)="Submit(approvallist[i].orderIdDetails)">
                      <i _ngcontent-moe-c2="" aria-hidden="true"
                         class="fa fa-pencil"></i> Submit
                    </button>

                  </div>
                </div>
              </mat-expansion-panel>
            </mat-accordion>




          </p-tabPanel>
        <!--</div>-->
        <!--<p-tabPanel header="Override">
          Francis Ford Coppola's legendary continuation and sequel to his landmark 1972 film, The_Godfather parallels the
          young Vito Corleone's rise with his son Michael's spiritual fall, deepening The_Godfather's depiction of the
          dark side of the American dream. In the early 1900s, the child Vito flees his Sicilian village for America after
          the local Mafia kills his family. Vito struggles to make a living, legally or illegally, for his wife and
          growing brood in Little Italy, killing the local Black Hand Fanucci after he demands his customary cut of the
          tyro's business. With Fanucci gone, Vito's communal stature grows.
        </p-tabPanel>-->

      </p-tabView>
    </div>
  </div>
  <p-dialog header="Workflow History" class="main-modal" [(visible)]="Workflow" [style]="{width: '70%'}" [modal]="true">
    Content
  </p-dialog>

  <p-dialog header="Notes" class="main-modal" [(visible)]="notes" [style]="{width: '70%'}" [modal]="true">
    <div class="user-info" *ngFor="let val of commentsList">
      <div class="user-pic-thumb">{{val.CreatedBy.charAt(0)}}</div>
      <div class="user-details">
        <h4>
          {{val.CreatedBy}}<i _ngcontent-moe-c2="" aria-hidden="true"  class="fa fa-times ml-1 close-icon" (click)="deleteComments(val.CommentId)"></i>
        </h4>
        
        <div class="p-info">
          {{val.CreatedDate | timezone : 'Eastern'}}
        </div>
        <div class="comment-details">
          {{val.Comment}}
        </div>
      </div>


    </div>

  </p-dialog>
</section>




<app-addressstripe></app-addressstripe>

<div mat-dialog-content>
  {{message}}
</div>
<div mat-dialog-actions>
  <button mat-button [mat-dialog-close]="true" cdkFocusInitial>Yes</button>
  <button mat-button (click)="onNoClick()">No</button>
</div>

<!--<p-dialog header="Dialogue Confirmation" [responsive]="true" showEffect="fade" [modal]="true" [style]="{width: '70%'}" mat-dialog-content >
    <div class="col-12 popup-inner" >
      <div class="row m-0">
        <div class="col-12">
          <div class="row m-0 form-controlwrap">
            <div class="col-6">
              {{message}}
            </div>
            <div class="col-6">
              <button mat-button (click)="onNoClick()">No</button>
              <button mat-button [mat-dialog-close]="true" cdkFocusInitial>Yes</button>
            </div>
          </div>
        </div>
      </div>
    </div>
</p-dialog>-->

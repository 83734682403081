import { Injectable, Inject } from '@angular/core';
import { GlobalVariable } from './common/globalvariable';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { CommonserviceService } from './common/services/commonservice.service';
import { CookieService } from 'ngx-cookie-service';
import { finalize } from 'rxjs/operators';
import { Userdetails } from './Models/userdetails';
import { AzureADUserDetailsModel } from './Models/azure-aduser-details-model';
import * as _ from 'lodash';
import { UserStoreLocation } from './Models/user-store-location.model';
import { Selectedstorelocations } from './Models/selectedstorelocations.model';
import { browser } from 'protractor';
import { Fund } from './Models/fund';


@Injectable()
export class AppinitService {
    allocatedFund: any;

  constructor(
 
    private cmnSvc: CommonserviceService,
    private http: HttpClient,
    private cookieService: CookieService,

    @Inject('BASE_URL') private baseUrl?: string) { }
  Init() {

    return new Promise<void>((resolve, reject) => {
      const httpOptions = {
        headers: new HttpHeaders({
          'skip-adal': 'true'
        })
      };
      this.http.get("/assets/environments/enviroment.json", httpOptions).subscribe((env: any) => {
        //this.adalService.init(env["config"]);
        //this.adalService.handleWindowCallback();
        //if (!this.adalService.userInfo.authenticated) {
        //  this.adalService.clearCache();
        //  this.adalService.login();
        //} else {
        // this.http.get(this.baseUrl + 'api/Account/Login').subscribe((value: AzureADUserDetailsModel) => {
        // this.cmnSvc.UserAdInformation = value;
        if (env["emailtest"] == true) {
          this.cmnSvc.navigateEmail = true;
          resolve();
        } else {


          if (!this.cookieService.check("MSAL_Login")) {


            console.log("ifloop")
           
            var returnurl = document.URL;
            window.location.href = this.baseUrl + 'Login/CheckLogin';
          }
          else {
     
            this.http.get(this.baseUrl + 'api/UserDetial/GetUserDetail').pipe(finalize(() => {
            })).subscribe((usrVal: Userdetails) => {

              this.cmnSvc.UserDetails = usrVal;
              this.cmnSvc.roleConfig = usrVal ? usrVal.RoleConfig ? usrVal.RoleConfig : "" : "";
              if (+usrVal.officeLocation != 0) {
                const jbcode = usrVal.JobCode ? usrVal.JobCode : "";
                this.http.get(this.baseUrl + 'api/Homepage/GetUserStoreLocation?StoreNumber=' + +usrVal.officeLocation + "&Jobcode=" + jbcode + "&UserId=" + this.cmnSvc.UserDetails.UserId).subscribe((data: UserStoreLocation[]) => {
                  _.each(data, (store) => {
                    store.StoreUserEMail = usrVal.EmailId;//this.cmnSvc.UserAdInformation.mail ? this.cmnSvc.UserAdInformation.mail : this.cmnSvc.UserAdInformation.userPrincipalName;
                  })
                  if (data && data.length > 0) {
                    this.cmnSvc.userStoreLocation = _.map(data, (value: UserStoreLocation) => {
                      if (value.Approver) {
                        value.Flag = true;
                      } else {
                        value.Flag = false;
                        //value.StoreNumber = 0;
                      }
                      return value;
                    });
                    this.cmnSvc.selectedstorelocations = this.cmnSvc.userStoreLocation.find(a => a.StoreNumber == +usrVal.officeLocation) ? this.cmnSvc.userStoreLocation.find(a => a.StoreNumber == +usrVal.officeLocation) : this.cmnSvc.userStoreLocation[0];

                  } else {
                    let store = new Selectedstorelocations();
                    store.StoreNumber = 0;
                    store.Account = 0;
                    store.Address1 = "";
                    store.Address2 = "";
                    store.Approver = 0;
                    store.ApproverEmail = "";
                    store.BannerName = "";
                    store.City = "";
                    store.County = "";
                    store.Dept = 0;
                    store.Description = "";
                    store.Phone = "";
                    store.State = "";
                    store.StoreType = "";
                    store.StoreUserEMail = "";
                    store.TaxPercent = 0;
                    store.TimeZone = "";
                    store.ZipCode = 0;
                    store.Flag = false;
                    this.cmnSvc.userStoreLocation = [];
                    this.cmnSvc.selectedstorelocations = store;
                  }
                  let strnum = 0;
                  if (this.cmnSvc.selectedstorelocations.Flag)
                    strnum = data == null ? this.cmnSvc.selectedstorelocations.StoreNumber : this.cmnSvc.selectedstorelocations.StoreNumber;
                  this.http.get(this.baseUrl + 'api/Homepage/GetAccountBalance?locationid=' + strnum)
                    .subscribe((cnt: Fund[]) => {
                      _.each(cnt, (cn) => {
                        cn.RemainingFund = cn.AllocatedFund - cn.UsedFund;
                        cn.AllocatedFund = cn.RemainingFund;
                      });
                      _.each(cnt, (outer: Fund) => {
                        _.each(cnt, (inner: Fund) => {
                          if (outer.CategoryName != inner.CategoryName && outer.CategoryName != "Store Services" && inner.CategoryName != "Store Services") {

                            if (outer.GlAccno.trim() === inner.GlAccno.trim()) {
                              outer.isSync = true;
                              inner.isSync = true;
                            }

                          }
                        })
                      });
                      //cnt = cnt.filter(x => x.CategoryName != "Store Services");
                      //var valuearr = cnt.map(items => items.GlAccno);
               
                      //var index = [];
                      //var isdup = valuearr.some((items, idx) => {
                      
                      //  if (valuearr.indexOf(items) != idx) {
                      //    index[0] = valuearr.indexOf(items);
                      //    index[1] = idx;
                        
                      //  }
                      
                      // return  valuearr.indexOf(items) != idx;
                      //});
                      //_.map(index, (iterate: any) => {
                      //  cnt[iterate].isSync = true;
                      //  this.cmnSvc.syncedGlAccno = cnt[index[0]].GlAccno;
                      //})
                     
                      this.cmnSvc.AllocatedFund = cnt;
                      if (env["emailtest"] == true) {
                        this.cmnSvc.navigateEmail = true;

                      }
                      resolve();
                    });
                });

                this.cmnSvc.UserDetails.AzureAdRole = usrVal.JobCode ? (usrVal.JobCode != "null" ? usrVal.JobCode :null) : null;
              } else {
                let store = new Selectedstorelocations();
                store.StoreNumber = 0;
                store.Account = 0;
                store.Address1 = "";
                store.Address2 = "";
                store.Approver = 0;
                store.ApproverEmail = "";
                store.BannerName = "";
                store.City = "";
                store.County = "";
                store.Dept = 0;
                store.Description = "";
                store.Phone = "";
                store.State = "";
                store.StoreType = "";
                store.StoreUserEMail = "";
                store.TaxPercent = 0;
                store.TimeZone = "";
                store.ZipCode = 0;
                this.cmnSvc.userStoreLocation = [];
                this.cmnSvc.selectedstorelocations = store;
                resolve();
              }
            });
          }
          //}
        }
      });
    });
  }
}

export interface AxureADUserDetail {
  AzureADId: string;
  FirstName: string;
  MiddleName: string;
  LastName: string;
  Email: string;
}

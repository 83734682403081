import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { MyorderComponent } from './myorder/myorder.component';
import { MyorderdetailsComponent } from '../myorderdetails/myorderdetails/myorderdetails.component';
import { MyorderadddetailsComponent } from '../myorderadddetails/myorderadddetails/myorderadddetails.component';

const routes: Routes = [
  {
    path: '',
    component: MyorderComponent
    , children: [
      {
        path: 'myorderlist',
        loadChildren: () => import('../myorderdetails/myorderdetails.module').then(m => m.MyorderdetailsModule)
      },
      {
        path: 'myorderadddetails',
        loadChildren: () => import('../myorderadddetails/myorderadddetails.module').then(m => m.MyorderadddetailsModule)
      },
      {
        path: '', redirectTo: 'myorderlist', pathMatch: 'full'
      }
    ]
  }
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class MyorderRoutingModule { }

import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { TableModule } from 'primeng/table';
import { OrderhistoryRoutingModule } from './orderhistory-routing.module';
import { OrderhistoryComponent } from './orderhistory/orderhistory.component';
import { BreadcrumbModule } from 'primeng/breadcrumb';
import { AddressstripeModule } from '../addressstripe/addressstripe.module';
import { TimezoneModule } from 'src/app/pipes/timezonepipe/timezone/timezone.module';
import { DialogModule } from 'primeng/dialog';
import { LazyLoadImageModule } from 'ng-lazyload-image';
import { RadioButtonModule } from 'primeng/radiobutton';
import { FormsModule } from '@angular/forms';
import { TabViewModule } from 'primeng/tabview';
import { MatExpansionModule } from '@angular/material/expansion';
import { StorenumberpipeModule } from '../../pipes/storenumberpipe/storenumberpipe/storenumberpipe.module';
import { CalendarModule } from 'primeng/calendar';


@NgModule({
  declarations: [OrderhistoryComponent],
  imports: [
    CommonModule,
    OrderhistoryRoutingModule,
    TableModule,
    BreadcrumbModule,
    AddressstripeModule,
    TimezoneModule,
    DialogModule,
    LazyLoadImageModule,
    RadioButtonModule,
    TabViewModule,
    MatExpansionModule,
    FormsModule,
    StorenumberpipeModule,
    CalendarModule
  ],
  exports: [
    OrderhistoryComponent
  ]
})
export class OrderhistoryModule { }

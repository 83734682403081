import { Component, OnInit, OnDestroy } from '@angular/core';
import { MessageService } from 'primeng/api';
import { CommonserviceService } from './common/services/commonservice.service';
import { Subscription } from 'rxjs';
import { Router, NavigationEnd, ActivatedRoute } from '@angular/router';
import { filter, map } from 'rxjs/operators';
import { HeaderService } from './module/header/service/header.service';
import { BnNgIdleService } from 'bn-ng-idle';
@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit, OnDestroy {
  title = 'ClientApp';
  isLogout: boolean;
  hasrole: boolean = true;
  subscription = new Subscription;
  constructor(private route: Router, private messageService: MessageService, private hdrSvc: HeaderService,
    private cmnsvc: CommonserviceService, private bnIdle: BnNgIdleService) {

    const sub = this.route.events.pipe(filter((e): e is NavigationEnd => e instanceof NavigationEnd),
      map((e) => {
        window.scrollTo(0, 0)
        const url = e.url;
        this.isLogout = false;
        if (url.includes("logout"))
          this.isLogout = true;
      })).subscribe();
    this.subscription.add(sub);

    //this.cmnsvc.isPageNotFound.subscribe((status: boolean) => {
    // this.isLogout = status;
    // })
  }


  ngOnDestroy() {
    this.hdrSvc.logout().subscribe();
  }
  ngOnInit() {
    this.hasrole = this.cmnsvc.UserDetails.JobCode ? true : false;

    this.bnIdle.startWatching(1200).subscribe((isTimedOut: boolean) => {
      if (isTimedOut) {
        this.route.navigateByUrl('/logout');
        this.hdrSvc.logout().subscribe();
      }
    });
    this.cmnsvc.toast.subscribe((value: any) => {
      if (this.messageService) {
        this.messageService.clear();
        if (value.severity != '')
          this.messageService.add(value);
      }
    });

  }
  exit() {
    this.hdrSvc.logout().subscribe();
    this.route.navigateByUrl("/logout")
    this.hasrole = true;
  }
}


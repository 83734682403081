<!--<p-table [value]="cars">
  <ng-template pTemplate="header">
    <tr>
      <th>Vin</th>
      <th>Year</th>
      <th>Brand</th>
      <th>Color</th>
    </tr>
  </ng-template>
  <ng-template pTemplate="body" let-car>
    <tr>
      <td>{{car.vin}}</td>
      <td>{{car.year}}</td>
      <td>{{car.brand}}</td>
      <td>{{car.color}}</td>
    </tr>
  </ng-template>
</p-table>-->
<app-approvaldashboard [CartId]="CartId" [isBulk]="isBulk"></app-approvaldashboard>

export class Selectedstorelocations {
  public StoreNumber: number;
  public Description: string;
  public BannerName: string;
  public TaxPercent: number;
  public Approver: number;
  public Address1: string;
  public Address2: string;
  public Phone: string;
  public County: string;
  public City: string;
  public State: string;
  public ZipCode: number;
  public StoreType: string;
  public Account: number;
  public Dept: number;
  public ApproverEmail: string;
  public StoreUserEMail: string;
  public TimeZone: string;
  public Flag: boolean;
  public RegionNumber: number;
  public RegionName: string;
  public IsCorp: boolean;
}



<section class="seg-topband-warp">
  <div class="container p-0">
    <div class="row m-0">
      <div class="col-12 col-md-1 col-lg-3 p-0 d-none d-lg-block">
        <div class="seg-top-left">
          Hi <b>{{currentuser}}</b>,Welcome to SEG Store Supplies Ordering
        </div>
      </div>
      <div class="col-6 col-md-9 col-lg-6 p-0">
        <div class="seg-top-middle m-0" *ngIf="isShowBudget">
          Available Budget :
          <div *ngIf="avlbuget > 0;then Positive else Negative"></div>
          <ng-template #Positive>
            <span class="avl-budget badge badge-pill badge-success">{{avlbuget| currency}}</span>
          </ng-template>
          <ng-template #Negative>
            <span class="cons-budget badge badge-pill badge-danger">{{avlbuget| currency}}</span>
          </ng-template>
            <span class="divider">|</span>
            Consumed Budget :
            <span class="cons-budget badge badge-pill badge-danger">{{consbuget| currency}}</span>
            <i class="fa fa-arrow-down"></i>
        </div>
      </div>
      
      <div class="col-6 col-md-3 col-lg-3 seg-top-right">
        <!--<p-dropdown [options]="helpdoclist" [(ngModel)]="selecteddoc" placeholder="Help" (click)="onclick()" (onChange)="onchange($event)" ></p-dropdown>-->
        <p-menu #helpmenu [model]="helpdoclist" [popup]="true"></p-menu>
        <button type="button" pButton (click)="helpmenu.toggle($event)"> Help <i class="fa fa-angle-down"></i> </button>
        <span *ngIf="showeditdialog" class="fa fa-pencil edit-icon" (click)="showDialog()"></span>
      </div>
    </div>
  </div>
  <!--Tableview-->
  <!--Fileupload-->

  <p-dialog [(visible)]="showdialog" showHeader="false" (onHide)="onhide()">
    <div class="col-12 popup-inner">
      <button (click)="insertdoc()" class="btn main-btn ml-0 mb-2 mt-2">Add A Document</button>
      <p-table [value]="showdoc">
        <ng-template pTemplate="caption">
          Help Documents

        </ng-template>
        <ng-template pTemplate="header">
          <tr>
            <th>Document Name</th>
            <th>Assigned To</th>
            <th>Action</th>

          </tr>
        </ng-template>
        <ng-template pTemplate="body" let-list>
          <tr>

            <td *ngIf="!list.onEdit">
              <i _ngcontent-moe-c2="" aria-hidden="true" class="fa fa-times" (click)="showconf(list)"></i>
              {{list.DocumentName}}
            </td>
            <td *ngIf="!list.onEdit">
              {{list.Role}}
             
            </td>
            <td *ngIf="list.onEdit">
              <input pInputText  [(ngModel)]="DocumentName" />
            </td>
            <td *ngIf="list.onEdit">
              <p-multiSelect [options]="roles" [(ngModel)]="oneditrole" class="form-control p-0">
                <span class="ui-multiselect-label">Choose</span>
              </p-multiSelect>

             

            </td>
            <td>
              <span *ngIf="!list.onEdit">
                <i _ngcontent-moe-c2="" aria-hidden="true" class="fa fa-pencil" (click)="onEdit(list) "></i>
              </span>
              <span *ngIf="list.onEdit" class="ml-2">
                <i _ngcontent-moe-c2="" aria-hidden="true" class="fa fa-check" (click)="onSave(list) "></i>
                <i _ngcontent-moe-c2="" aria-hidden="true" class="fa fa-times" (click)="cancelshowdialog(list)"></i>
              </span>
            </td>



          </tr>

        </ng-template>
      </p-table>

    </div>
    
    <!--<button type="button" pButton icon="fa fa-check" (click)="cancelshowdialog()" label="Cancel"></button>-->
  </p-dialog>

  <div class="main-bg" *ngIf="showupload">
    <p-dialog [(visible)]="showupload" showHeader="false">

      <form [formGroup]="DocForm" #formDir="ngForm">
        <div class="col-12 popup-inner">
          <div class="row">
            <div class="col-12 col-md-6">
              <div class="col-12 p-0">
                <label for="DocumentUrl">Document</label>
                <p-fileUpload [files]="uploadedFiles"
                              maxFileSize="1000000000"
                              customUpload="true"
                              auto="auto"
                              (uploadHandler)="myUploader($event)"
                              (onUpload)="onUpload($event)"
                              (onRemove)="onRemove($event)">

                  <ng-template pTemplate="content">

                    <ul *ngIf="Files.length">
                      <li *ngFor="let file of Files">{{file.name}} - {{file.size}} bytes</li>
                    </ul>

                  </ng-template>

                </p-fileUpload>
                <div class="alert alert-danger" *ngIf="!isuploaded && formDir.submitted">
                  document required

                </div>
              </div>
                
            </div>
            
            <div class="col-12 col-md-6">
              <div class="col-12 p-0">
                <label for="Role">Role</label>
                <p-multiSelect [options]="roles" formControlName="Role" scrollHeight=100px class="form-control p-0">

                  <span class="ui-multiselect-label">Choose</span>
                </p-multiSelect>
                <div class="alert alert-danger" *ngIf="DocForm.hasError('required', 'Role') && formDir.submitted">
                  role required
                </div>
              </div>
              
            </div>
           

          </div>
        </div>
        
        <p-footer>
          <div class="ui-dialog-buttonpane ui-helper-clearfix text-right edit-foot mb-5">
            <button type="submit" pButton icon="fa fa-check" (click)="save(DocForm,formDir)" label="Save" class="mr-3"></button>
            <button type="button" pButton icon="fa fa-check" (click)="cancel()" label="Cancel"></button>
          </div>
        </p-footer>
      </form>

    </p-dialog>
  </div>
  <p-dialog header="" [(visible)]="showConf" [modal]="true" [responsive]="true" [style]="{width: '450px'}">
    <div class="col-12 text-center">
      <p class="qstion">
        Are you sure to delete this record?
      </p>
    </div>
    
    <p-footer class="btn-footer">
      <div class="ui-dialog-buttonpane ui-helper-clearfix edit-foot text-center mt-3 mb-3">
        <button type="button" (click)="showConfirm()" class="btn main-btn">Ok</button>
        <button type="button" (click)="showConf=false" class="btn main-btn">Cancel</button>
      </div>        
    </p-footer>
  </p-dialog>
</section>

import { Injectable } from '@angular/core';
import { DalbaseService } from '../../../common/dalbase.service';
import { HttpClient } from '@angular/common/http';
import { SearchOrderHistoryModel, GetOrderHisoryModel, ApprovalReportModel } from '../model/orderhistory';
import { Comments } from '../../approvaldashboard/model/approval.model';

@Injectable({
  providedIn: 'root'
})
export class OrderhistoryService extends DalbaseService {

  constructor(private httpClient: HttpClient) {
    super(httpClient);
  }
  

  getOrderHistory(value: GetOrderHisoryModel) {
    return this.executePost('OrderHistory/GetOrderHistory', value);
  }
  getreorderdetails(ItemId: number, UserId: number) {
    return this.executeGet('OrderHistory/GetReorderDetails?ItemId=' + ItemId + '&UserId=' + UserId);
  }
  getcoments(CartId: number) {
    return this.executeGet('Approval/GetComments?CartId=' + CartId);
  }
  cancelorder(Product, UserId) {

    //return this.executeGet('OrderHistory/CancelOrder?OrderId=' + Product.OrderId + '&ItemId=' + Product.ItemId + '&Date=' + Product.ModifiedDate + '&VendorId=' + Product.VendorId + '&StoreLocation=' + Product.StoreLocation + '&UserId=' + UserId + '&CartId=' + Product.UserCartId);
    return this.executePost('OrderHistory/CancelOrder', Product);
  }
  Cancelpsorderid(product) {
    return this.executePost('PSOrder/CancelPSOrderId', product);
  }
  SyncReferenceId() {
    return this.executeGet('PSOrder/InsertPSOrderId');
  }
  getBulkOrderHistory(UserId: number, storeId: number, Skip: number, IsSearch: boolean, SearchParameter: string, Field: string, SortBy: string) {
    return this.executeGet('OrderHistory/GetBulkOrderHistory?UserId=' + UserId + '&StoreId=' + storeId + '&Skip=' + Skip + "&IsSearch=" + IsSearch + "&SearchParameter=" + SearchParameter + "&SortBy=" + SortBy + "&Field=" + Field);
  }
  GetPSOrderId(OrderId,ItemId,CartId,VendorId)
  {
    return this.executeGet('OrderHistory/GetPSOrderId?OrderId=' + OrderId + '&ItemId=' + ItemId + '&CartId=' + CartId + '&VendorId=' + VendorId );
  }
  GetBulkOrderDetails(OrderId) {
    return this.executeGet('OrderHistory/GetBulkOrderDetails?OrderId=' + OrderId)
  }
  GetApprovalReportHistory(approvalreport: ApprovalReportModel) {
    return this.executePost('OrderHistory/GetApprovalReportHistory', approvalreport);
  }
  insertcomments(UserId: number, Comment: string, CartId: number) {
    let comment = new Comments();
    comment.UserId = UserId;
    comment.CartId = CartId;
    comment.Comment = Comment;
    return this.executePost('Approval/InsertComments', comment);
  }
  DeleteComments(CommentId: number) {
    return this.executeGet('Approval/DeleteComments?CommentId=' + CommentId);
  }
}

import { Component, OnInit, OnDestroy } from '@angular/core';
import { CommonserviceService } from '../../../common/services/commonservice.service';
import { Subscription } from 'rxjs';
import { Cart } from '../../../Models/cart';
//import { MenuItem } from 'primeng/components/common/menuitem';
import { MenuItem } from 'primeng/api';
import { Fund } from '../../../Models/Fund';
import { MyorderdetailsService } from '../../myorderdetails/service/myorderdetails.service';
import { Router } from '@angular/router';
import * as _ from 'lodash';
import { Bulkorder, BulkorderFunds } from '../../myorderadddetails/model/bulkorder.model';


@Component({
  selector: 'app-myorder',
  templateUrl: './myorder.component.html',
  styleUrls: ['./myorder.component.scss']
})


export class MyorderComponent implements OnInit, OnDestroy {
  cartValue: Cart;
  subcription: Subscription = new Subscription();
  items: MenuItem[] = [];
  funds: Fund[];
  bulkorder: Bulkorder[];
  bulkorderfunds: BulkorderFunds;

  showConf: boolean;
  //mnupdate
  showConfnew: boolean;

    iscorp: boolean;
  constructor(private cmnsvc: CommonserviceService, private svc: MyorderdetailsService, private router: Router) { }

  ngOnInit() {
  
   
      let shippingCost = 0;
      this.cmnsvc.bulkorder.subscribe((data: Bulkorder[]) => {
        this.bulkorder = _.cloneDeep(data);
        if (this.cmnsvc.selectedstorelocations.IsCorp) {
          this.bulkorderfunds = new BulkorderFunds();
          this.bulkorderfunds.TotalCartValue = 0;
          this.bulkorderfunds.ShippingCost = 0;
          this.bulkorderfunds.TaxPercent = 0;
          this.bulkorderfunds.GrandTotal = 0;
          _.each(this.bulkorder, (iterate: Bulkorder) => {
            const itVlue = iterate.ItemCount * (+iterate.Price);
            const estiCst = itVlue * +(iterate.EstimateShippingCost / 100);
            shippingCost = shippingCost + estiCst;
            const salesTx = +(itVlue * (+(iterate.TaxPercent / 100))).toFixed(3);

            this.bulkorderfunds.TotalCartValue += itVlue;
            this.bulkorderfunds.ShippingCost += shippingCost;
            this.bulkorderfunds.TaxPercent += salesTx;
            this.bulkorderfunds.GrandTotal += +(itVlue + shippingCost + salesTx).toFixed(3);

          });
          this.cmnsvc.FinalCartValue.TotalCartValue = this.bulkorderfunds.TotalCartValue;
          this.cmnsvc.FinalCartValue.GrandTotal = this.bulkorderfunds.GrandTotal;
          this.cmnsvc.FinalCartValue.ShippingCost = this.bulkorderfunds.ShippingCost;
          this.cmnsvc.FinalCartValue.TaxPercent = this.bulkorderfunds.TaxPercent;
    
        }
      });
    
     this.funds = _.cloneDeep(this.cmnsvc.AllocatedFund);
   
    const fsub = this.cmnsvc.Fund.subscribe((fnd: Fund[]) => {
      if (fnd && fnd.length > 0) {
        this.funds = _.cloneDeep(fnd);

      
        this.funds = this.funds.filter(x => x.GLAccountDescription != "PO Office Supplies/Smallwares" && x.GLAccountDescription != "COS WHSE (Warehouse)");
         
        _.each(this.funds, (iterate: Fund) => {

          if (iterate.CategoryName == "Office Supplies") {
            iterate.CategoryName = "Office Supplies/Smallwares";
          }

        });
      
      }
     
    });
    if (this.cmnsvc.selectedstorelocations.IsCorp) {
      this.iscorp = true;
    }


    this.items = [
      { label: 'Home', routerLink: ['/home'] },
      { label: 'My Orders' }
    ];
    const sub = this.cmnsvc.TotlCartValue.subscribe((cartVal: Cart) => {
    
  
      this.cartValue = cartVal;
      this.cmnsvc.FinalCartValue = this.cartValue;
    });
    this.subcription.add(sub);

    const conf = this.svc.confirmBox.subscribe((show: boolean) => {
      this.showConfirm();
    });
    this.subcription.add(conf);

    //mnupdate
    const conf2 = this.svc.confirmBoxnew.subscribe((show: boolean) => {
      this.showConfirmnew();
    });
    this.subcription.add(conf2);

  
  }
  ngOnDestroy() {
    this.subcription.unsubscribe();
  }
  showConfirm() {
    if (this.iscorp) {
      this.router.navigateByUrl('/myorder/myorderadddetails');
    }
    else {
      this.showConf; 
      this.showConf = !this.showConf;
      if (!this.showConf)
        this.router.navigateByUrl('/myorder/myorderadddetails');

      this.svc.hasAckgd = true;
    }
  }

  //mnupdate
  showConfirmnew() {
    if (this.iscorp) {
      //this.router.navigateByUrl('/myorder/myorderadddetails');
      this.showConfnew;
      this.showConfnew = !this.showConfnew;
      if (!this.showConfnew)
        this.router.navigateByUrl('/myorder/myorderadddetails');

      this.svc.hasAckgd = true;
    }
    else {
      this.showConfnew;
      this.showConfnew = !this.showConfnew;
      if (!this.showConfnew)
        this.router.navigateByUrl('/myorder/myorderadddetails');

      this.svc.hasAckgd = true;
    }
  }



}

import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { BreadcrumbModule } from '../breadcrumb/breadcrumb.module';
import { MyorderRoutingModule } from './myorder-routing.module';
import { MyorderComponent } from './myorder/myorder.component';
import { DialogModule } from 'primeng/dialog';
import { AddressstripeModule } from '../addressstripe/addressstripe.module';

@NgModule({
  declarations: [MyorderComponent],
  imports: [
    CommonModule,
    BreadcrumbModule,
    MyorderRoutingModule,
    AddressstripeModule,
    DialogModule
  ]
})
export class MyorderModule { }

export class Orderhistory {
  UserCartId: number;
  ItemId: number;
  Description: string;
  CategoryId: number;
  OrderedBy: string;
  Price: number;
  ModifiedDate: Date;
  OrderId: string;
  ImageUrl: string;
  IsActive: boolean;
  IsReorder: boolean;
  OrderedItem: number;
  DeliveryLocationId: number;
  Status: string;
  TimeZone: string;
  ItemName: string;
  MaxAllowedToOrder: number;
  CompanyName: string;
  ItemCount: number;
  ItemListId: number;
  UnitOfMeasure: string;
  UserCartItemId: string;
  TotalCount: number[];
  Category: string;
  WishListId: number;
  EstimateShippingCost: number;
  VendorName: string;
  UserId: number;
  isactive: boolean;
  TotalRecords: number;
  VendorId: number;
  GLAccountNo: string;
  PSOrderId: string;
  Nature: string;
  StoreLocation: string;
  StoreNumber: number;
  CategoryCode: number;
  TotalItemCount: number;
  TotalStoreNumber: number;
  TotalPrice: number;
  UnitPrice: number;
  hasbudget: boolean;
  CreatedTS: Date;
  Processed: boolean;
  WDCode: number;
}
export class OrderCancelModel {
  OrderId: string;
  ItemId: number;
  UserCartId: number;
  VendorId: number;

}
export class GetOrderHisoryModel {
    
  UserId: number;
  StoreNumber: number;
  IsSearch: boolean;
  Skip: number;
  SortBy: string;
  SearchQuery: string;
  SortOrder:string
  OrderId: string;
  StoreLocation: string;
  Description: string;
  Category: string;
  Date: string;
  OrderedBy: string;
  OrderValue: string;
  Fullorderhistory: number; //mn
  WDCode: string;
}
export class SearchOrderHistoryModel {
  OrderId: string;
  StoreLocation: number;
  Description: string;
  Date: string;
  OrderedBy: number;
  OrderValue: string;
  WDCode: number;
 
}
export class ApprovalReportModel {

  UserId: number;
  Status: string;
  StoreNumber: number;
  IsSearch: boolean;
  Skip: number;
  SortBy: string;
  SearchQuery: string;
  SortOrder: string
  StoreLocation: string;
  Description: string;
  Date: string;
  OrderedBy: string;
  OrderValue: string;
  OrderId: string;
  FiscalYear: number;
  Period: number;
  Region: number;
  Banner: string;
  ItemCode: string;
  Store: number;
  WDCode: string;
  BdgtAllctdFrom: number;
  BdgtAllctdTo: number;
  BdgtCnsmdFrom: number;
  BdgtCnsmdTo: number;
}



<div class="container-fluid bg-white mt-4 bt-bb">
  <div class="container address-stripe">
    <div class="row">
      <div class="col-12 col-xl-4 address-item">
        <div class="add-icon">
          <img src="./assets/img/iPhone.png" class="img-fluid">
        </div>
        <h2>Call us</h2>
        <h3>{{contactnumber}}</h3>
      </div>
      <div class="col-12 col-xl-4 address-item">
        <div class="add-icon">
          <img src="./assets/img/location.png" class="img-fluid">
        </div>
        <h2>Location</h2>
        <p>{{contactaddress}}</p>
      </div>
      <div class="col-12 col-xl-4 address-item">
        <div class="add-icon">
          <img src="./assets/img/mail.png" class="img-fluid">
        </div>
        <h2>Mail</h2>
        <a href="mailto:{{email}}">{{email}}</a>
      </div>
    </div>
  </div>
</div>

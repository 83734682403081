export interface Inbox {
  storeid: string;
  datetime: string;
  totalamount: number;
  submitter: string;
  approver: string;
  status: OrderStatus;
  children?: ProductInfo[];
}
export interface ProductInfo {
  productIMG: string;
  productName: string;
  productItemNo: number;
  productPack: string;
  productBy: string;
  productQty: number;
  productPrice: number;
  status: ProductStatus;
}
export enum OrderStatus {
  Pending,
  Approved,
  OnHold,
  Canceled
}
export enum ProductStatus {
  Pending,
  Approved,
  OnHold,
  Canceled
}

import { Injectable } from '@angular/core';
import { DalbaseService } from 'src/app/common/dalbase.service';
import { HttpClient } from '@angular/common/http';
import { OrderIdDetails, Approval, Comments, DashoardReportModel } from '../model/approval.model';

@Injectable({
  providedIn: 'root'
})
export class ApprovalDashboardService extends DalbaseService {

  constructor(private httpclient: HttpClient) {
    super(httpclient);
  }

  getApprovalList(UserId: number, LocationId: number) {
    return this.executeGet('Approval/GetApprovalList?UserId=' + UserId + '&LocationId=' + LocationId);
  }
  getorderdetails(CartId: number, OrderId: string) {
    return this.executeGet('Approval/GetOrderDetails?CartId=' + CartId + '&OrderId=' + OrderId);
  }
  updateorderdetails(Appr: Approval) {
    let comment = new Approval();
    return this.executePost('Approval/UpdateOrderDetails', Appr);
  }
  insertcomments(UserId: number, Comment: string, CartId: number) {
    let comment = new Comments();
    comment.UserId = UserId;
    comment.CartId = CartId;
    comment.Comment = Comment;
    return this.executePost('Approval/InsertComments', comment);
  }
  getcoments(CartId: number) {
    return this.executeGet('Approval/GetComments?CartId=' + CartId);
  }
  DeleteComments(CommentId: number) {
    return this.executeGet('Approval/DeleteComments?CommentId=' + CommentId);
  }

  setapprovalstatus(orderIdDetails) {

    return this.executePost('Approval/SetApprovalStatus', orderIdDetails);
  }
  getorderiddetails(CartId: number, isBulk: number) {
    return this.executeGet('Approval/GetOrderIdDetails?CartId=' + CartId + '&isBulk=' + isBulk);
  }
  GetOrderIdApprDetails(OrderId: string) {
    return this.executeGet('Approval/GetOrderIdApprDetails?OrderId=' + OrderId);
  }
  GetDashboardfromReport(dashboardreport: DashoardReportModel) {
    return this.executePost('Approval/GetDashboardfromReport', dashboardreport);
  }
}

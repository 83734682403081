import { Injectable } from '@angular/core';
import { DalbaseService } from '../../../common/dalbase.service';
import { HttpClient } from '@angular/common/http';
import { forkJoin } from 'rxjs';
import { Observable } from 'rxjs';
@Injectable({
  providedIn: 'root'
})
export class NavbarService extends DalbaseService {

  constructor(private httpClient: HttpClient) {
    super(httpClient);
  }

  public getMenuList(userid: number = 0, storenumber : number): Observable<any[]> {
    const allCategories = this.executeGet('MenuItems/GetAllCategories');
    const mainMenuList = this.executeGet('MenuItems/GetMainMenuList?UserId=' + userid + '&StoreNumber=' + storenumber);
    return forkJoin([allCategories, mainMenuList]);
  }
  getCategoryListItem() {
    return this.executeGet('MenuItems/GetAllCategories');
  }

  getMainMenuList(userid: number) {
    return this.executeGet('MenuItems/GetMainMenuList?UserId=' + userid);
  }
  getCartCount(UserId: number) {
    return this.executeGet('Homepage/GetCartCount?UserId=' + UserId);
  }

  DeleteCartItem(UserId: number) {
    return this.executeGet('Cart/DeleteCartItem?UserId=' + UserId);
  }
  logout() {
    return this.executeGet('Account/Logout');
  }
}

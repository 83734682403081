import { Injectable, Inject } from '@angular/core';
import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest, HttpResponse, HttpErrorResponse } from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { LoadingService } from './services/loading.service';
import { finalize, map, catchError } from 'rxjs/operators';
import { CookieService } from 'ngx-cookie-service';
import { CommonserviceService } from '../../common/services/commonservice.service'

@Injectable()
export class Loading implements HttpInterceptor {
  activeRequests = 0;
  Version = "0";
  /**
   * URLs for which the loading screen should not be enabled
   */
  skippUrls = [];
  constructor(private loaderService: LoadingService,
    private cookie: CookieService, private cmnsvc: CommonserviceService,

    @Inject('BASE_URL') private baseUrl?: string) {
  }
  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {


   // var token = "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJzdG9yZW51bSI6IjAwMDMiLCJnaXZlbl9uYW1lIjoiU2hpdmEiLCJmYW1pbHlfbmFtZSI6IlByYXNhZCIsImVtYWlsIjoianVzdGluYS5qb2JAY2xheXN5cy5uZXQiLCJ1bmlxdWVfbmFtZSI6InNoaXZhQGRhbmNlMTgwLmNvbSIsIkpvYkNvZGUiOiIyNzc4IiwibmJmIjoxNjQ4MjkwNDQ1LCJleHAiOjE2NDgzNzY4NDUsImlhdCI6MTY0ODI5MDQ0NX0.F1lt3j8JElKwJ0CdzTKFPSymmYibZXey4O3VXlIMxNU";
   // var token = "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJzdG9yZW51bSI6IjAwMDMiLCJnaXZlbl9uYW1lIjoiU2hpdmEiLCJmYW1pbHlfbmFtZSI6IlByYXNhZCIsImVtYWlsIjoianVzdGluYS5qb2JAY2xheXN5cy5uZXQiLCJ1bmlxdWVfbmFtZSI6InNoaXZhQGRhbmNlMTgwLmNvbSIsIkpvYkNvZGUiOiIyNzc4IiwibmJmIjoxNjQ4NDk5Nzk3LCJleHAiOjE2NDg1ODYxOTcsImlhdCI6MTY0ODQ5OTc5N30.jYWlcDpbLcsjuRM6Z3MTJCwRhrveDiCeKhsOq0KEr_Q";
    var token = this.cookie.get("MSAL_Login");

    const nextReq = request.clone({
      headers: request.headers
        .set("Authorization", 'Bearer ' + token)
        .set('Cache-Control', 'no-cache')
        .set('Pragma', 'no-cache')
        .set('Expires', 'Sat, 01 Jan 2000 00:00:00 GMT')
        .set('If-Modified-Since', '0')
        .set("Version", this.Version)

    });
    const displayLoaderScreen = true;
    let showLoader = true;
    if (request.url.includes("Budget/UpdateBudget"))
      showLoader = false;
    else if (request.url.includes("Budget/UploadBudgetFile"))
      showLoader = false;
    else if (request.url.includes("DashboardReport"))
      showLoader = false;

    if (showLoader) {
      this.loaderService.startLoader();
      this.activeRequests++;
    }
    if (displayLoaderScreen) {
      //if (this.activeRequests === 0) {
      //  this.loaderService.startLoader();
      //}

      return next.handle(nextReq).pipe(
        map((event: HttpEvent<any>) => {
          if (event instanceof HttpResponse) {
            this.Version = event.headers.get("Version") ? event.headers.get("Version") : "0";
            if (event.headers.get("VersionMismatch") == "True")
              this.loaderService.hasChanges.next(true);
          }
          return event;
        }),
        catchError((error: HttpErrorResponse) => {
          if (error.status == 0)
            console.log(this.baseUrl + 'Login/CheckLogin3');
          //this.cmnsvc.showToast('error', 'API error', 'Please contact administrator');
          console.log("API error Please contact administrator", error)
            /*window.location.href = this.baseUrl + 'Login/CheckLogin';*/
          return throwError(error);
        }),
        finalize(() => {
          this.activeRequests--;
          if (this.activeRequests <= 0) {
            this.activeRequests = 0;
            this.loaderService.stopLoader();
          }
        })
      )
    } else {
      return next.handle(nextReq);
    }
  }

}

import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { StorelocationPipe } from '../../storelocation.pipe';



@NgModule({
  declarations: [StorelocationPipe],
  imports: [
    CommonModule,
    
  ],
  exports: [StorelocationPipe]
})
export class StorenumberpipeModule { }

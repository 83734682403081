import { Injectable } from '@angular/core';
import { CanActivate, CanActivateChild, ActivatedRouteSnapshot, RouterStateSnapshot, UrlTree, Router } from '@angular/router';
import { Observable } from 'rxjs';

import { CommonserviceService } from './services/commonservice.service';


@Injectable({
  providedIn: 'root'
})
export class AuthGuardGuard implements CanActivate, CanActivateChild {
  constructor(private cmnsvc: CommonserviceService, private router: Router) { }

  canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): Observable<boolean> | Promise<boolean> | boolean {

    if (this.cmnsvc.UserDetails && this.cmnsvc.UserDetails.JobCode) {
 
      if (this.cmnsvc.checkRole(route.url[0].path)) {
        return true;
      } else {
        this.router.navigateByUrl("/404")
        return false;
      }
    } else {
      this.router.navigateByUrl("/404")
      return false;
    }
  }

  public canActivateChild(
    childRoute: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): Observable<boolean> | Promise<boolean> | boolean {
    if (this.canActivate(childRoute, state)) {
      return true;
    } else {

      //this.adalService.login();
    }
  }
}

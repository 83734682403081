import { Injectable, EventEmitter } from '@angular/core';
import { DalbaseService } from '../../../common/dalbase.service';
import { HttpClient } from '@angular/common/http';
import { forkJoin, Observable } from 'rxjs';
import { Fund } from '../../../Models/Fund';
import { CommonserviceService } from '../../../common/services/commonservice.service';
import { ok } from 'assert';
import { Bulkorder } from '../../myorderadddetails/model/bulkorder.model';
import { checkwhitemmodel } from '../model/myorderdetails';
import { Comments } from '../../approvaldashboard/model/approval.model';
@Injectable({
  providedIn: 'root'
})
export class MyorderdetailsService extends DalbaseService {
  cartid: number;
  confirmBox: EventEmitter<boolean> = new EventEmitter();


  //mnupdate
  confirmBoxnew: EventEmitter<boolean> = new EventEmitter();

  locationChange: EventEmitter<boolean> = new EventEmitter();
  hasAckgd: boolean = false;
  constructor(private httpClient: HttpClient, private cmnSvc: CommonserviceService) {
    super(httpClient);
  }

  getCartItemList(UserId: number) {
   
    return this.executeGet('Cart/GetCartItemList?UserId=' + UserId + '&StoreNumber=' + this.cmnSvc.selectedstorelocations.StoreNumber);
  }

  removeCartItem(UserId: number, ItemId: number, CartId: number) {
    return this.executeGet('Cart/RemoveCartItem?UserId=' + UserId + '&ItemId=' + ItemId + '&CartId=' + CartId);
  }

  saveforLater(UserId: number, ItemId: number, CartId: number, ItemCount: number, VendorId: number, CategoryId: number) {
    return this.executeGet('Cart/SaveforLater?UserId=' + UserId + '&ItemId=' +
      ItemId + '&CartId=' + CartId + '&ItemCount=' + ItemCount + '&VendorId=' + VendorId + '&CategoryId=' + CategoryId);
  }

  getCart(UserId: number, CategoryId: number): Observable<any> {
 
    const cart = this.executeGet('Cart/GetCartItemList?UserId=' + UserId);
    const saved = this.executeGet('Cart/GetSavedForLaterItems?UserId=' + UserId + '&CategoryId=' + CategoryId);
    return forkJoin([cart, saved]);
  }

  checkout(UserId: number, CartId: number, TotalAmount: number, IsRequireApproval: boolean,
    fund: any, DeliveryLocationId: number, appr: any): Observable<any> {
 
    const cart = this.executeGet('Cart/UpdateCheckoutStatus?UserId=' + UserId + '&CartId=' + CartId +
      '&TotalAmount=' + TotalAmount + '&IsRequireApproval=' + IsRequireApproval + '&DeliveryLocationId=' + DeliveryLocationId);
    const fnd = this.executePost('Cart/UpdateFund', fund);
    //const app = this.executePost('Cart/InsertToApproval', appr);
    return forkJoin([cart, fnd]);
  }
  inserttoapproval(appr: any) {
    return this.executePost('Cart/InsertToApproval', appr);
   
  }
  SendXMlmail(appr: any) {
   return this.executePost('Cart/SendXMlmail', appr);
  
  }
  Sendmail(appr: any) {
    var res = this.executePost('Cart/Sendmail', appr);
    return res;

  }
  addSavedItemstoCart(UserId: number, ItemId: number, CartId: number, CategoryId: number, VendorId: number, StoreNumber: number) {
    return this.executeGet('Cart/AddSavedItemstoCart?UserId=' + UserId + '&ItemId=' +
      ItemId + '&CartId=' + CartId + '&CategoryId=' + CategoryId + '&VendorId=' + VendorId + '&StoreNumber=' + StoreNumber);
  }
  removeFromSaveForLater(UserId: number, UserCartItemId: number) {
    return this.executeGet('Cart/RemoveFromSaveForLater?UserId=' + UserId + '&ItemId=' +
      UserCartItemId);
  }
  addToWishList(UserId: number, ItemId: number, CategoryId: number, VendorId: number) {
    return this.executeGet('Homepage/AddToWishList?UserId=' + UserId + '&ItemId=' + ItemId);
  }

  getAccountBalance(LocationId: number) {
    return this.executeGet('Homepage/GetAccountBalance?locationid=' + LocationId);
  }

  UpdateCartCount(UserId: number, ItemId: number, CartId: number, Count: number) {
    return this.executeGet('Cart/UpdateCartCount?UserId=' + UserId + '&ItemId=' + ItemId +
      '&CartId=' + CartId + '&Count=' + Count);
  }

  GetStoreLocationsForAdmins(query: string) {
    return this.executeGet('Cart/GetStoreLocationsForAdmins?query=' + query);
  }

  CheckAccountBalance(fund: any) {
    return this.executePost('Cart/CheckAccountBalance', fund);
  }
  InsertBulkOrder(bulkorder: Bulkorder[]) {
    return this.executePost('Cart/InsertBulkOrder', bulkorder);
  }
  GetallOrderId(CartId: number) {
    return this.executeGet('Cart/GetallOrderId?CartId=' + CartId);
  }
  GetallBulkOrder(CartId: number) {
    return this.executeGet('Cart/GetallBulkOrder?CartId=' + CartId);
  }
  CancelStoreOrder(CartId: number, ItemId: number, StoreNumber:number) {
    return this.executeGet('Cart/CancelStoreOrder?CartId=' + CartId + '&ItemId=' + ItemId + '&StoreNumber=' + StoreNumber);
  }
  GetVendorsOrderId(CartId: number, ItemId: number, VendorId: number) {
    return this.executeGet('Cart/GetVendorsOrderId?CartId=' + CartId + '&ItemId=' + ItemId + '&VendorId=' + VendorId);
  }
  CheckWhItems(checkwhitem: checkwhitemmodel[]) {
    return this.executePost('ManageItem/CheckWHItem', checkwhitem);
  }
  insertcomments(UserId: number, Comment: string, CartId: number) {
    let comment = new Comments();
    comment.UserId = UserId;
    comment.CartId = CartId;
    comment.Comment = Comment;
    return this.executePost('Approval/InsertComments', comment);
  }
  getcoments(CartId: number) {
    return this.executeGet('Approval/GetComments?CartId=' + CartId);
  }
  DeleteComments(CommentId: number) {
    return this.executeGet('Approval/DeleteComments?CommentId=' + CommentId);
  }
  
}

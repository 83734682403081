import { Injectable } from '@angular/core';
import { DalbaseService } from '../dalbase.service';
import { HttpClient } from '@angular/common/http';
import { CommonserviceService } from './commonservice.service';
import { forkJoin, Observable } from 'rxjs';
@Injectable({
  providedIn: 'root'
})
export class CartService extends DalbaseService {

  constructor(private httpclient: HttpClient, private cmnSvc: CommonserviceService) {
    super(httpclient);
  }
  addtoCart(UserId: number, ItemId: number, CatergoryId: number, CartCount: number = 1, Price: number, VendorId: number) {
    return this.executeGet('Homepage/AddtoCart?UserId=' +
      UserId + '&ItemId=' + ItemId + '&CatergoryId=' +
      CatergoryId + '&CartCount=' + CartCount + '&Price=' + Price + '&StoreNumber=' + this.cmnSvc.selectedstorelocations.StoreNumber + '&VendorId=' + VendorId);
  }
  addToWishList(UserId: number, ItemId: number, CategoryId:number,VendorId :number) {
    return this.executeGet('Homepage/AddToWishList?UserId=' + UserId + '&ItemId=' + ItemId + '&CategoryId=' + CategoryId + '&VendorId=' + VendorId);
  }
  getDuplicateOrder(ItemId: number,CategoryId: number, StoreNo: number) {
    return this.executeGet('Homepage/GetDuplicateOrder?ItemId=' + ItemId + '&CategoryId=' + CategoryId + '&StoreNo=' + StoreNo);
  }
  getItemFinalized(CartId:number,ItemId: number, CategoryId: number, StoreNo: number) {
    return this.executeGet('Homepage/GetItemFinalized?CartId='+CartId+'&ItemId=' + ItemId + '&CategoryId=' + CategoryId + '&StoreNo=' + StoreNo);
  }

 

  removeFromWishList(WishListId: number, Status: boolean) {
    return this.executeGet('Homepage/RemoveFromWishList?WishListId=' + WishListId + '&Status=' + Status);
  }
  uploadFile(file: any[], ItemListId: number,VendorId : number) {
    const formData: FormData = new FormData();

    formData.append('Image', file[0]);
    formData.append('ItemListId', ItemListId.toString());
    formData.append('VendorId', VendorId.toString());
    return this.executePost('/Attachment/uploadFile', formData);
  }

  ExceptionHandler(error) {
    return this.executePost('ExceptionHandler/InsertErrorLog', error);
  }

  getCart(UserId: number, CategoryId: number): Observable<any> {
    const cart = this.executeGet('Cart/GetCartItemList?UserId=' + UserId);
    const saved = this.executeGet('Cart/GetSavedForLaterItems?UserId=' + UserId + '&CategoryId=' + CategoryId);
    return forkJoin([cart, saved]);
  }

  getAccountBalance(LocationId: number): Observable<any> {
    if (!this.cmnSvc.selectedstorelocations.Flag)
      LocationId = 0;
    return this.executeGet('Homepage/GetAccountBalance?locationid=' + LocationId);
  }
}

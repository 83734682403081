import { BrowserModule } from '@angular/platform-browser';
import { NgModule, APP_INITIALIZER, ErrorHandler, NgZone  } from '@angular/core';
import { HttpClientModule, HTTP_INTERCEPTORS  } from '@angular/common/http';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { AppinitService } from './appinit.service';
// import { TableModule } from 'primeng/table';
import { GlobalErrorHandlerService } from './common/errorhandler/global-error-handler.service';
import { ProgressBarModule } from 'primeng/progressbar';
import { FileUploadModule } from 'primeng/fileupload';
import { AccordionModule } from 'primeng/accordion';
// import { HomeModule } from './module/home/home.module';
import { MyorderModule } from './module/myorder/myorder.module';
import { OrderhistoryModule } from './module/orderhistory/orderhistory.module';
import { HeaderModule } from './module/header/header.module';
import { NavbarModule } from './module/navbar/navbar.module';
import { FooterModule } from './module/footer/footer.module';
import { HttpClient } from '@angular/common/http';
import { DatePipe } from '@angular/common';
import { AuthGuardGuard } from './common/auth-guard.guard';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { CommonserviceService } from './common/services/commonservice.service';
import { ToastModule } from 'primeng/toast';
import { MessageService } from 'primeng/api';
import { ServiceWorkerModule } from '@angular/service-worker';
import { environment } from '../environments/environment';
import { Loading } from './module/loading/loading.interceptor';
import { LoadingComponent } from './module/loading/loading/loading.component';
import { FileuploadModule } from './module/fileupload/fileupload/fileupload.module';
import { CarouselModule } from 'primeng/carousel';
import { HttpInterceptorService } from './common/HttpInterceptor/http-interceptor.service';
import { ConfirmationDialogComponent } from './common/confirmation-dialog/confirmation-dialog.component';
import { MatDialogModule } from '@angular/material/dialog';
import { MatButtonModule } from '@angular/material/button';
import { InjectionToken } from '@angular/core';
import { CookieService } from 'ngx-cookie-service';
import { NgCircleProgressModule } from 'ng-circle-progress';
import { DialogModule } from 'primeng/dialog';

import { PageNotFoundModule } from './page-not-found/page-not-found.module';

import { ApprovaldashboardModule } from './module/approvaldashboard/approvaldashboard.module';

export const BASE_URL = new InjectionToken<string>('BASE_URL');

export function initializeApp(appinitService: AppinitService) {
  return (): Promise<any> => {
    return appinitService.Init();
  };
}
@NgModule({
    declarations: [
        AppComponent,
        LoadingComponent,
    ConfirmationDialogComponent,


    ],
    imports: [
        CarouselModule,
        FileuploadModule,
        BrowserModule,
        AppRoutingModule,
        HttpClientModule,
        AccordionModule,
        MatButtonModule,
        PageNotFoundModule,
        ApprovaldashboardModule,
        // TableModule,
        // BreadcrumbModule,
        MyorderModule,
        OrderhistoryModule,
        HeaderModule,
        NavbarModule,
        FooterModule,
        BrowserAnimationsModule,
        FileUploadModule,
        ToastModule,
        DialogModule,
        ProgressBarModule,
        ServiceWorkerModule.register('ngsw-worker.js', { enabled: environment.production }),
        MatDialogModule,
        NgCircleProgressModule.forRoot({
            // set defaults here
            radius: 100,
            outerStrokeWidth: 16,
            innerStrokeWidth: 8,
            outerStrokeColor: "#78C000",
            innerStrokeColor: "#C7E596",
            animationDuration: 300,
        })
    ],
    providers: [
        AppinitService,
        DatePipe,
        CookieService,
        MessageService,
        {
            provide: APP_INITIALIZER,
            useFactory: initializeApp,
            deps: [AppinitService, CommonserviceService, HttpClient], multi: true
        },
        {
            provide: ErrorHandler,
            useClass: GlobalErrorHandlerService
        },
        {
            provide: HTTP_INTERCEPTORS,
            useClass: Loading,
            multi: true
        },
        { provide: BASE_URL, useValue: "http://localhost:44343/api" },
        AuthGuardGuard
    ],
    exports: [],
    bootstrap: [AppComponent]
})
export class AppModule { }

<section class="seg-orderhistory">
  <div class="col-12 px-lg-4">

    <div class="row align-items-lg-baseline">
      <div class="col-12">
        <p-breadcrumb [model]="items" class="custom-breadcrumb"></p-breadcrumb>
      </div>

    </div>
    <div class="choos-history-type">
      <p-radioButton label="OrderHistory" value="orderhistory" [(ngModel)]="selectedValue" class="mr-3"></p-radioButton>
      <p-radioButton label="BulkOrderHistory" value="bulkorderhistory" [(ngModel)]="selectedValue" [disabled]="role != 'SuperAdmin'"></p-radioButton>

    </div>
    <div *ngIf="selectedValue == 'orderhistory'" class="order-history-warp">
      <p-table #tt class="main-table" [columns]="cols" [value]="sales" [responsive]="true" [lazy]="true" (onLazyLoad)="loadLazy($event)" [globalFilterFields]="['OrderId','ItemName']" selectionMode="single" [paginator]="true" [rows]="20" [totalRecords]="totalRecords">
        <ng-template pTemplate="caption">

          <div class="col-12 col-lg-12 float-right p-0 text-right mb-2">
            <div class="row">

              <div class="col-12 col-lg-9 green-btn-warp local-btn-warp">
                <button *ngIf="role == 'SuperAdmin'" (click)="refresh()" class="btn green-btn" style="min-width:1px"><span class="fa fa-refresh" aria-hidden="true"></span></button>
                <button *ngIf="role == 'SuperAdmin'" (click)="sync()" class="btn green-btn">Sync PS Ref ID</button>
              </div>
              <div class="col-12 col-lg-3" style="float:right">
                <i class="fa fa-search"></i>
                <input class="w-100" type="text" pInputText size="50" placeholder="Search by Item Name or Order Id" (input)="tt.filterGlobal($event.target.value, 'contains')">
              </div>
            </div>

          </div>
        </ng-template>

        <!--<ng-template pTemplate="header" let-columns>
          <tr>
            <th *ngFor="let col of columns" [pSortableColumn]="col.field">
              {{col.header}}
              <p-sortIcon [field]="col.field"></p-sortIcon>
            </th>
          </tr>
        </ng-template>-->
        <ng-template pTemplate="header">
          <tr>
            <th pSortableColumn="OrderId">
              Order ID# <p-sortIcon field="OrderId"></p-sortIcon>
              <br />Purchase Order ID#
            </th>
            <!--wdcode-->
            <th pSortableColumn="WDCode">WDCode <p-sortIcon field="WDCode"></p-sortIcon></th>
            <th>Image <p-sortIcon field="ImageUrl"></p-sortIcon></th>
            <th pSortableColumn="Description">Item Name <p-sortIcon field="Description"></p-sortIcon></th>
            <th pSortableColumn="Category">Category <p-sortIcon field="Category"></p-sortIcon></th>
            <th pSortableColumn="ModifiedTS">Date <p-sortIcon field="ModifiedTS"></p-sortIcon></th>
            <th pSortableColumn="OrderedBy">Ordered by <p-sortIcon field="OrderedBy"></p-sortIcon></th>
            <th pSortableColumn="StoreLocation">Store<p-sortIcon field="StoreLocation"></p-sortIcon></th>
            <th pSortableColumn="Price">Order Value  <p-sortIcon field="Price"></p-sortIcon></th>
            <th pSortableColumn="Status">Order Status <p-sortIcon field="Status"></p-sortIcon></th>
            <th pSortableColumn="Status">Notes</th>
            <th>CancelOrder</th>
            <th>Action</th>
          </tr>

          <tr>

            <th *ngFor="let col of cols">
              <span *ngIf="showfilter">
                <input *ngIf="col.field != 'Action' && (role == 'SuperAdmin' || 'StoreManager') && col.field != 'Status' && col.field != 'ImageUrl' && col.field != 'Status' && col.field != 'Price' && col.field != 'ModifiedTS'  && col.field != '' "
                       pInputText type="text" (input)="onfilter($event.target.value,col.field)" class="p-inputtext p-corner-all">
                <div *ngIf="col.field == 'ModifiedTS' && (role == 'SuperAdmin' || 'StoreManager')">
                  <p-calendar [(ngModel)]="date" (onSelect)="onfilter($event,col.field)" dateFormat="dd.mm.yy" (onInput)="clear($event)"></p-calendar>
                </div>
              </span>
            </th>
          </tr>

        </ng-template>

        <ng-template pTemplate="body" let-products let-columns="columns">

          <tr *ngIf="result">
            <td>
              <!--class change ui-column-title to p-column-title-->
              <span class="p-column-title">Order ID# </span>

              <span class="red" title="{{products.OrderId}}">{{products.OrderId}} </span>
              <span class="red psordr-id" title="{{products.PSOrderId}}">{{products.PSOrderId}} </span>


            </td>
          <!--  wdcode-->
            <td>
              <span class="p-column-title">WDCode</span>
              <span class="red">{{products.WDCode}} </span>
            </td>
            <td>
              <span class="p-column-title">Product Image</span>
              <img [defaultImage]="defaultImage" [lazyLoad]="(products.ImageUrl?products.ImageUrl:'assets/img/no-image.jpg')" (error)="updateUrl(products)" (click)="recentView(products)" alt="order_hist" />
            </td>
            <td>
              <span class="p-column-title">Description</span>
              {{products.ItemName}}
            </td>

            <td>
              <span class="p-column-title">Category</span>
              {{products.Category}}
            </td>

            <td>
              <span class="p-column-title">Date</span>
              {{products.ModifiedDate | timezone : products.TimeZone }}
            </td>

            <td>
              <span class="p-column-title">Ordered By</span>
              {{products.OrderedBy}}
            </td>
            <td>
              <span class="p-column-title">Store</span>
              {{products.StoreLocation | storelocation}}
            </td>
            <td>
              <span class="p-column-title">Order Value</span>
              Total : <span class="red">{{products.Price | currency}}</span>
            </td>
            <td>
              <span class="p-column-title">Order Status</span>
              <span class="red">{{products.Status | titlecase}}</span>
            </td>
            <td data-label="Notes" class="notes">
              <button type="button" (click)="GetNote(products.UserCartId)"> <i class="fa fa-commenting" aria-hidden="true"></i> </button>
            </td>

            <td class="notes">

              <button type="button" class="re-order-btn" (click)="CancelOrder(products,false)" [disabled]="(role != 'SuperAdmin'  && products.Category == 'Store Services') || products.Processed || products.isactive || products.Status !='Approved'"> Cancel order </button>

            </td>



            <td class="text-right">

              <button type="button" class="re-order-btn" (click)="addToCart(products);" [disabled]="(role != 'SuperAdmin' && products.Category == 'Store Services') ||  !products.hasbudget">
                <i class="fa fa-repeat" aria-hidden="true"></i>
                Buy Again
              </button>


            </td>
          </tr>
        </ng-template>

      </p-table>
    </div>

    <div class="bulk-order-warp" *ngIf="selectedValue == 'bulkorderhistory'">
      <div class="col-lg-3 bulk-search p-0" *ngIf="role == 'SuperAdmin'">
        <input type="text" placeholder="Search by OrderId" [(ngModel)]="orderid" (input)="bt.filterGlobal($event.target.value, 'contains')" />
        <span class="fa fa-search search-icon"></span>
      </div>
      <!--<div class="acc-table">-->
      <p-table #bt [value]="bulkorderlist" class="w-100 max-table" [lazy]="true" dataKey="OrderId" (onRowExpand)="openGroup($event)" (onLazyLoad)="loadLazybulk($event)" [paginator]="true" [rows]="20" [totalRecords]="totalRecords" rowExpandMode="single" expandableRows="true">
        <ng-template pTemplate="header">
          <tr>
            <th pSortableColumn="OrderId">
              Order ID/Name<p-sortIcon field="OrderId"></p-sortIcon>
              <br />Purchase Order ID#
            </th>
           
            <!--<th>Store Name</th>-->
            <th pSortableColumn="CreatedTS">Order Placed Date<p-sortIcon field="CreatedTS"></p-sortIcon></th>
            <th pSortableColumn="TotalPrice">Total Amount<p-sortIcon field="TotalPrice"></p-sortIcon></th>
            <th pSortableColumn="OrderedBy">Submitter<p-sortIcon field="OrderedBy"></p-sortIcon></th>

            <th pSortableColumn="TotalStoreNumber">Total Stores<p-sortIcon field="TotalStoreNumber"></p-sortIcon></th>
            <th pSortableColumn="TotalItemCount">Total Quantity<p-sortIcon field="TotalItemCount"></p-sortIcon></th>
            <th pSortableColumn="Status">Status<p-sortIcon field="Status"></p-sortIcon></th>
            <th pSortableColumn="Status">Notes</th>
            <th>Action</th>
         
          </tr>
        </ng-template>

        <!--</div>-->
        <!--<div class="bulk-panel">-->
        <ng-template pTemplate="body" let-list let-expanded="expanded">

          <tr>
            <!--<table class="w-100 list-table">
            <tr class="{{ list.OrderId  }}">
              <td data-label="Store ID/Name" class="{{list.Status}}">
                <span class="store-bag">
                  <i class="fa fa-shopping-bag"
                     aria-hidden="true"></i>
                </span>{{list.OrderId}}
              </td>-->
            <td>
              <a href="#" [pRowToggler]="list">
                <i [ngClass]="expanded ? 'pi pi-fw pi-chevron-circle-down' : 'pi pi-fw pi-chevron-circle-right'"></i>
              </a>
              {{ list.OrderId  }}
              <br />
              <span class="red" style="margin-left:25px">{{list.PSOrderId}} </span>
            </td>

            <td data-label="Order Placed Date">{{list.CreatedTS | timezone : 'Eastern' }}</td>
            <td data-label="Total Amount">{{list.TotalPrice | currency}}</td>
            <td data-label="Submitter">{{list.OrderedBy }}</td>
            <td data-label="Approver">{{list.TotalStoreNumber}}</td>
            <td data-label="Approver">{{list.TotalItemCount}}</td>
            <td data-label="Approver">{{list.Status}}</td>
            <td data-label="Notes" class="notes">
              <button type="button" (click)="GetNote(list.UserCartId)"> <i class="fa fa-commenting" aria-hidden="true"></i> </button>
            </td>
            <td data-label="Notes" class="notes">

              <button type="button" class="re-order-btn" (click)="CancelOrder(list,true)" [disabled]="list.Status == 'Cancelled' || list.Status == 'Pending' "> Cancel order </button>

            </td>

          </tr>  <!--</table>-->
        </ng-template>
        <ng-template pTemplate="rowexpansion" let-list>
          <div class="rowexpansion_block" *ngFor="let orderdetails of bulkorderdetails">
            <table border="0" cellpadding="0" cellspacing="0">
              <tr>
                <td>
                  <b>Item Name : </b>
                  {{orderdetails.ItemName}}
                </td>

                <td>
                  <b>Item Id : </b>
                  #{{orderdetails.ItemId}}
                </td>

                <td>
                  <b>Count : </b>
                  {{orderdetails.UnitOfMeasure}} of {{orderdetails.ItemCount}}
                </td>

                <td>
                  <b>CompanyName : </b>
                  {{orderdetails.CompanyName}}
                </td>

                <td>
                  <b>Store : </b>
                  {{orderdetails.StoreLocation | storelocation}}
                </td>
                <td>
                  <b>Status:</b>
                  {{orderdetails.Status}}
                </td>

                <td>
                  <b>Price : </b>
                  {{orderdetails.Price | currency}}
                </td>
              </tr>
            </table>
          </div>


        </ng-template>
      </p-table>
      <!--</div>-->
    </div>
    <div *ngIf="!result" style="
  margin-top: auto; margin-left:45%; font-size: 20px;
  font-weight: 500">
      No Results Found

    </div>

    <p-dialog header="Notes" class="main-modal" [(visible)]="notes" [style]="{width: '70%'}" [modal]="true">
      <div class="green-btn-warp ml-0 text-right mb-2">
        <button *ngIf="role == 'SuperAdmin'" (click)="onClickAddNote()" class="btn green-btn pl-20 pr-20" style="min-width:1px !important">
          <span class="fa fa-plus mr-8" aria-hidden="true"></span> Add
        </button>
      </div>
      <div *ngIf="isAddNote">
        <textarea rows="2" cols="50" class="form-control" placeholder="Notes" [(ngModel)]="comment"></textarea>

        <div class="green-btn-warp local-btn-warp text-right mt-2 mb-4">
          <button *ngIf="role == 'SuperAdmin'" (click)="AddNote()" class="btn green-btn" style="min-width:1px">Submit</button>
        </div>
      </div>
      <div class="user-info" *ngFor="let val of commentsList">
        <div class="user-pic-thumb">{{val.CreatedBy.charAt(0)}}</div>
        <div class="user-details">
          <h4>
            {{val.CreatedBy}} <i _ngcontent-moe-c2="" aria-hidden="true" class="fa fa-times ml-1 close-icon" (click)="deleteComments(val.CommentId)"></i>
          </h4>

          <div class="p-info">
            {{val.CreatedDate | timezone : 'Eastern'}}
          </div>
          <div class="comment-details">
            {{val.Comment}}
          </div>
        </div>


      </div>

    </p-dialog>

  </div>
</section>

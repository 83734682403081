import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'storelocation'
})
export class StorelocationPipe implements PipeTransform {

  transform(storelocation: number): any {
    if (storelocation) {
      let _storelocation = storelocation.toString();
      while (_storelocation.length < 4) { _storelocation = "0" + _storelocation; }
      return _storelocation;
    }
    else
      return null;
  }

}

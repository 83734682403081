import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { AddressstripeComponent } from './addressstripe/addressstripe.component';



@NgModule({
  declarations: [AddressstripeComponent],
  imports: [
    CommonModule
  ],
  exports: [AddressstripeComponent]
})
export class AddressstripeModule { }

import { Pipe, PipeTransform } from '@angular/core';
import * as moment from 'moment-timezone';
@Pipe({
  name: 'timezone'
})
export class TimezonePipe implements PipeTransform {

  transform(value: any, arg: any): any {
    var jun = moment.utc(value);

    switch (arg) {
      case 'Eastern': return jun.tz("US/Eastern").format('ddd, MMMM D YYYY, h:mm:ss A');
      case 'Pacific': return jun.tz("US/Pacific").format('ddd, MMMM D YYYY, h:mm:ss A');
      case 'Central': return jun.tz("US/Central").format('ddd, MMMM D YYYY, h:mm:ss A');
    }
  }

}

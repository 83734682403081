import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { CommonserviceService } from 'src/app/common/services/commonservice.service';
import { CartService } from 'src/app/common/services/cart.service';

@Component({
  selector: 'app-fileupload',
  templateUrl: './fileupload.component.html',
  styleUrls: ['./fileupload.component.scss']
})
export class FileuploadComponent implements OnInit {
  @Input()
  public ItemListId: number;
  @Input()
  public VendorId: number;
  isuploadfile: boolean;
  filetype: string;
  vendorid: number = 0;
  uploadedFiles: any[] = [];
  @Output() imageUrl = new EventEmitter<string>();
  constructor(private cmnsvc: CommonserviceService, private crtsvc: CartService) { }
  value = 0;
  ngOnInit() {
  }
  uploadHandler(event) {
    this.isuploadfile = true;
    for (const file of event.files) {
      this.filetype = file.type;
      this.filetype = this.filetype.toLowerCase();
      if (this.filetype === 'image/jpeg' || this.filetype === 'image/jpg' || this.filetype === 'image/png') {
        this.uploadedFiles.push(file);
        this.crtsvc.uploadFile(this.uploadedFiles, this.ItemListId, this.VendorId).subscribe((url: any) => {
          this.cmnsvc.showToast('success', 'SuccessMessage', 'Successfully Uploaded');
          this.uploadedFiles = [];
          this.imageUrl.emit(url.ImageUrl);
        });
      } else {
        this.cmnsvc.showToast('warn', 'Error Message', 'Error in Uploading Image');
        this.uploadedFiles = [];
      }
    }
  }

}

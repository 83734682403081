import { Component, OnInit, OnDestroy, Input } from '@angular/core';
import { Subscription } from 'rxjs';
import { Router, NavigationEnd, ActivatedRoute } from '@angular/router';
import { filter, map, finalize } from 'rxjs/operators';
import { CommonserviceService } from 'src/app/common/services/commonservice.service';
import { HeaderService } from '../service/header.service';
import { Fund } from '../../../Models/Fund';
import * as _ from 'lodash';
import { UntypedFormGroup, UntypedFormControl, Validators, FormGroupDirective } from '@angular/forms';
import { SelectItem, MenuItem } from 'primeng/api';
import { HelpDoc } from '../models/help-doc.model';


@Component({
  selector: 'app-topband',
  templateUrl: './topband.component.html',
  styleUrls: ['./topband.component.scss']
})
export class TopbandComponent implements OnInit, OnDestroy {
    //UntypedFormControl is simply pointing to FormControl with type any
  DocForm = new UntypedFormGroup({
    DocumentUrl: new UntypedFormControl('',),
    Role: new UntypedFormControl('', Validators.required),

  });
  isuploaded: boolean= false;
  showdoc: HelpDoc[];
  category: any;
  categoryNme: any;
  currentuser: string;
  avlbuget: number;
  showdialog: boolean = false;
  showupload: boolean = false;
  showhelpdoc: boolean = true;
  pdf: string;
  consbuget: number;
  currentrank: number;
  diffrate: number;
  show: boolean = false;
  isShowBudget: boolean;
  locationId: number;
  url: any;
  showlink: boolean = false;
  @Input() fund: Fund[];
  subscription: Subscription = new Subscription();
  roles: SelectItem[] = [];
  imagePath: any;
  imgURL: string | ArrayBuffer;
  categoryName: string;
  uploadedFiles: any[] = []; 
  myfile: any[];
  helpdoclist: MenuItem[];
  list: HelpDoc;
  selecteddoc: string;
  onedit: boolean = false;
  DocumentName: string;

  public helpdoc: HelpDoc = new HelpDoc();
  Files: any[] = [];
  oneditrole = [];
    showConf: boolean;
  showeditdialog: boolean = false;
  constructor(private route: Router, private routes: ActivatedRoute,
    private cmnSvc: CommonserviceService, private hmSvc: HeaderService) {
    this.roles = [
      { label: 'SuperAdmin', value: 'SuperAdmin' },
      { label: 'Admin', value: 'Admin' },
      { label: 'StoreManager', value: 'StoreManager' },

      // { label: 'Order on Behalf', value: 'orderonbehalf' }
    ];
   
  }

  ngOnInit() {
 

      
    
    this.show = this.cmnSvc.checkRole('admin');
    if (this.cmnSvc.UserDetails.Role == "SuperAdmin") {
      this.showeditdialog = true;
    }
    const sub = this.route.events.pipe(filter((e): e is NavigationEnd => e instanceof NavigationEnd),
      map((e) => {
        this.categoryName = this.routes.snapshot.queryParamMap.get('categoryName');
        this.url = e.url;

        if (e.url.includes('item') && !e.url.includes('-1') && !e.url.includes('admin') && this.categoryName != 'Store Services' && this.categoryName != 'Warehouse') {
          this.isShowBudget = true;
          this.getAccountBalance();
        } else {
          //console.log("debug")
          //this.isShowBudget = true;
          //this.getAccountBalance();
           this.isShowBudget = false;
        }
      })).subscribe();
  
      
      
   


 
    this.currentuser = this.cmnSvc.UserDetails ? this.cmnSvc.UserDetails.FirstName : null;

    this.currentrank = 35;
    this.diffrate = 5;
    this.subscription.add(sub);
    const fsub = this.cmnSvc.locationChangeEmitter.subscribe(() => {
      if (this.url.includes('item')) {
        this.getAccountBalance();
      }
    });
    this.onclick();
    this.subscription.add(fsub);
  }

  ngOnDestroy() {
    this.subscription.unsubscribe();
  }
  getAccountBalance() {
    const asub = this.hmSvc.getAccountBalance(this.cmnSvc.selectedstorelocations.StoreNumber).subscribe((cnt: any) => {
      _.each(cnt, (cn) => {
        cn.RemainingFund = cn.AllocatedFund - cn.UsedFund;
        cn.AllocatedFund = cn.RemainingFund;
      });
      _.each(cnt, (outer: Fund) => {
        _.each(cnt, (inner: Fund) => {
          if (outer.CategoryName != inner.CategoryName && outer.CategoryName != "Store Services" && inner.CategoryName != "Store Services") {

            if (outer.GlAccno.trim() == inner.GlAccno.trim()) {
              outer.isSync = true;
              inner.isSync = true;
            }

          }
        })
      });
      console.log("cnt=" + cnt);
      this.cmnSvc.AllocatedFund = cnt;
      this.fund = cnt;
      this.cmnSvc.Fund.emit(this.fund);

      //mnupdate
      this.avlbuget = this.fund.filter(a => a.CategoryId === +this.cmnSvc.Category.CategoryId)
        .reduce((sum, current) => sum + current.RemainingFund, 0);
      console.log("avl1=" + this.avlbuget);
      //check

      

      //this.avlbuget = this.fund.find(a => a.CategoryId === +this.cmnSvc.Category.CategoryId) ?
      //  this.fund.find(a => a.CategoryId === this.cmnSvc.Category.CategoryId).RemainingFund : 0;
      //console.log("avl2=" + this.avlbuget);

      //mnupdate
      this.consbuget = this.fund.filter(a => a.CategoryId === +this.cmnSvc.Category.CategoryId)
      .reduce((sum, current) => sum + current.UsedFund, 0);
      console.log("cbt=" + this.consbuget);


      //this.consbuget = this.fund.find(a => a.CategoryId === this.cmnSvc.Category.CategoryId) ?
      //this.fund.find(a => a.CategoryId === this.cmnSvc.Category.CategoryId).UsedFund : 0;
      //console.log("cbt=" + this.consbuget);



      



    });
    this.subscription.add(asub);
  }
  //onhelp() {
  //  this.hmSvc.onhelp().subscribe((pdf: any) => {
  //    this.pdf = pdf.filepath;
  //    window.open(this.pdf, "_blank");
  //  });
  //}
  showDialog() {
    this.hmSvc.gethelpdoc("").subscribe((data: any) => {
      this.showdoc = data;
      _.each(this.showdoc, (iterate: HelpDoc) => {
        iterate.onEdit = false;
        switch (iterate.Role) {
          case 'SuperAdmin': iterate._Role = "Super Admin"
            break;
          case 'Admin': iterate._Role = "Admin"
            break;
          case 'StoreManager': iterate._Role = "Store Manager"
            break;
        } })
       
      this.showdialog = true;
    });

   
  }
  insertdoc() {
    this.showupload = true;
    this.showdialog = false;
  }
  async save(formData: any, formDirective: FormGroupDirective) {
   
    if (this.uploadedFiles && this.DocForm.value.Role) {
      var fileurl;
      this.oneditrole;
      const dataurl = await this.hmSvc.uploadFiletoblob(this.uploadedFiles).toPromise().then(x => fileurl = x);
      this.helpdoc = this.DocForm.value;
    this.helpdoc.DocumentUrl = fileurl.filepath;
    this.helpdoc.Role = this.helpdoc.Role.toString();
      this.helpdoc.DocumentName = fileurl.filename;
    this.helpdoc.UserId = this.cmnSvc.UserDetails.UserId;
    
    if (this.DocForm.valid) {
      this.hmSvc.inserthelpdoc(this.helpdoc).subscribe((data: any) => {
        this.uploadedFiles = [];
        this.DocForm.reset();
        this.cmnSvc.showToast('success', 'Success Message', 'Successfully Uploaded');
      });
    }
    }
    formDirective.resetForm();
    this.DocForm.reset();

  }
  onEdit(list: HelpDoc) {
    list.onEdit = true;
    this.oneditrole = list.Role.split(',');
    this.DocumentName = list.DocumentName;
    //this.oneditrole.push({ label: list.Role, value: list.Role });
    this.onclick();

  }
  onSave(list: HelpDoc) {
    list.Role = this.oneditrole.toString();
    list.DocumentName = this.DocumentName;
    this.hmSvc.updatehelpdoc(list).subscribe((data: any) => {
      if (data == true) {
        this.cmnSvc.showToast('success', 'Success Message', 'Successfully Updated')
        this.oneditrole = [];
        list.onEdit = false;
        this.onclick();
      }
    });
   
  }
  onclick() {
    this.hmSvc.gethelpdoc(this.cmnSvc.UserDetails.Role).subscribe((data: any) => {


      this.helpdoclist = _.map(data, (iterate: any) => {
        return {
          label: iterate.DocumentName,
          //value: iterate.DocumentUrl
          command: e => this.onchange(iterate.DocId) 
        }
      });

      console.log(this.helpdoclist);
    });
  }
  onchange(DocId) {
    
    this.hmSvc.gethelpdocfromblob(DocId).subscribe((result:any) => {
      this.showFile(result, this.selecteddoc);
    });
  }
  private showFile(content: string, filename: string) {
    // It is necessary to create a new blob object with mime-type 
    // explicitly set otherwise only Chrome works like it should
    //let newBlob = new Blob([blob], { type: "application/pdf" });
    const blob = new Blob([content], { type: 'application/pdf' });

    // IE doesn't allow using a blob object directly as link href 
    // instead it is necessary to use msSaveOrOpenBlob
    const nav = (window.navigator as any);

    if (nav && nav.msSaveOrOpenBlob) {
      nav.msSaveOrOpenBlob(blob, filename);
      return;
    }
    else {
      // For other browsers:
      // Create a link pointing to the ObjectURL containing the blob.
      const url = window.URL.createObjectURL(blob);
      const link = document.createElement('a');
      link.href = url;
      link.download = filename;
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    }
  }
  myUploader(event): void {

    for (const file of event.files) {
      if (file.length === 0) {
        return;
      }
      console.log(this.cmnSvc.UserDetails.Role);
      this.uploadedFiles = [];
      this.uploadedFiles.push(file);
      this.isuploaded = true;
       event.files = [];

    }
    
    }
  onRemove($event) {
    this.isuploaded = false;
  }
  cancelshowdialog(list: HelpDoc) {
    
    list.onEdit = false;
  }
 
  cancel() {
    this.showupload = false;
    this.showhelpdoc = false;
  }
  // upload completed event

  onUpload(event): void {

    for (const file of event.files) {

      this.Files.push(file);

    }

  }
  showconf(list: HelpDoc) {
    this.showConf = true;
    this.list = list;
  }
  showConfirm() {
    this.list.Status = "I";
    this.hmSvc.updatehelpdoc(this.list).subscribe((data: any) => {
    if (data == true) {
      this.cmnSvc.showToast('success', 'Success Message', 'Successfully Updated')
      this.oneditrole = [];
      this.onedit = false;
      this.showConf = false;
      this.hmSvc.gethelpdoc("").subscribe((data: any) => {
        this.showdoc = data;
        _.each(this.showdoc, (iterate: HelpDoc) => {
          switch (iterate.Role) {
            case 'SuperAdmin': iterate._Role = "Super Admin"
              break;
            case 'Admin': iterate._Role = "Admin"
              break;
            case 'StoreManager': iterate._Role = "Store Manager"
              break;
          }
        })

        this.showdialog = true;
      });
    }
  });
  }
  onhide() {
    this.onedit = false;
  }
  public removeValidators(form: UntypedFormGroup) {
    for (const key in form.controls) {
      form.get(key).clearValidators();
      form.get(key).updateValueAndValidity();
    }
  }


  
  
  
}

import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { MenuModule } from 'primeng/menu';
import { ButtonModule } from 'primeng/button';
import { HeaderComponent } from './header/header.component';
import { MenuItem } from 'primeng/api';
import { RouterModule } from '@angular/router';
import { AutoCompleteModule } from 'primeng/autocomplete';
import { FormsModule } from '@angular/forms';
import { DropdownModule } from 'primeng/dropdown';
import { TopbandComponent } from './topband/topband.component';
import { DialogModule } from 'primeng/dialog';
import { FileUploadModule } from 'primeng/fileupload';
import { MultiSelectModule } from 'primeng/multiselect';
import { ReactiveFormsModule } from '@angular/forms';
import { TableModule } from 'primeng/table';
import { InputTextModule } from 'primeng/inputtext';
@NgModule({
  declarations: [HeaderComponent, TopbandComponent],
  imports: [
    CommonModule,
    BrowserAnimationsModule,
    MenuModule,
    ButtonModule,
    RouterModule,
    AutoCompleteModule,
    FormsModule,
    DropdownModule,
    DialogModule,
    FileUploadModule,
    MultiSelectModule,
    ReactiveFormsModule,
    TableModule,
    InputTextModule
  ],
  exports: [HeaderComponent, TopbandComponent]
})
export class HeaderModule { }

// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: true,
  config: {
    tenant: '2fa42031-4a75-4088-bc1b-6f188b56eb7f',
    clientId: 'e16fe1a6-d21c-4808-a3b8-3cf36680e485',
    endpoints: {
      'http://localhost:44343/': 'e16fe1a6-d21c-4808-a3b8-3cf36680e485',
      "https://graph.microsoft.com/": 'e16fe1a6-d21c-4808-a3b8-3cf36680e485'
    }
  },
  notifyUrl: 'https://localhost:44343/notify'
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/plugins/zone-error';  // Included with Angular CLI.

import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { ApprovaldashboardComponent } from './approvaldashboard/approvaldashboard.component';
import { MailapprovalComponent } from './mailapproval/mailapproval.component';
import { AuthGuardGuard } from 'src/app/common/auth-guard.guard';

const routes: Routes = [
  {
    path: '',
    component: ApprovaldashboardComponent
  }
  
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class ApprovaldashboardRoutingModule { }

import { Component, OnInit, OnDestroy, Inject } from '@angular/core';
import { LoadingService } from '../services/loading.service';
import { Router, NavigationStart, NavigationEnd } from '@angular/router';
import { Subscription } from 'rxjs';
import { CommonserviceService } from 'src/app/common/services/commonservice.service';


@Component({
  selector: 'app-loading',
  templateUrl: './loading.component.html',
  styleUrls: ['./loading.component.scss']
})
export class LoadingComponent implements OnInit, OnDestroy {

  mailer: boolean = false;
  mailloader: string = "0";
  loader = false;
  loaderSubscription: Subscription;
  showConf: boolean = false;
  url: string;
  constructor(private loaderService: LoadingService, private router: Router, private cmnsvc: CommonserviceService,
    @Inject('BASE_URL') private baseUrl?: string) {
    this.cmnsvc.loaderperc.subscribe((data: string) => {
      if (+this.mailloader >= 100) { this.mailloader = "0"; }

      this.mailloader = (+this.mailloader + +data).toString();
      if (data.length > 0) {
        this.loader = false;
        this.mailer = true;
      } else {
        this.loader = true;
        this.mailer = false;
      }

    });
    router.events.subscribe(e => {
      if (e instanceof NavigationStart) {
        this.url = e.url;
        this.loader = true;
      }

      if (e instanceof NavigationEnd) {
        this.loader = false;
        this.mailer = false;
      }

    });
  }

  continue() {

    this.showConf = false;
    window.location.href = this.baseUrl;
  }
  ngOnInit() {
    this.loaderService.hasChanges.subscribe(() => {
      if (!this.url.toLowerCase().includes("logout"))
        this.showConf = true;
    });

    this.loaderSubscription = this.loaderService.loaderStatus.subscribe((value) => {
      if (!this.mailer)
        this.loader = value;
    });

  }
  ngOnDestroy() {
    this.loaderSubscription.unsubscribe();
  }
}

import { Injectable, Inject} from '@angular/core';
import { HttpClient } from '@angular/common/http';
import * as gb from './globalvariable';
@Injectable({
  providedIn: 'root'
})
export abstract class DalbaseService {
  constructor(private http: HttpClient) { }
  executeGet(url: string) {
    return this.http.get(this.gentrateUrl(url));
  }
  protected gentrateUrl(url: string) {
    return  'api/' + url;
  }
  protected executePost<T>(url: string, data: any) {
    return this.http.post(this.gentrateUrl(url), data);
  }
}

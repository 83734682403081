import { Injectable } from '@angular/core';
import { DalbaseService } from 'src/app/common/dalbase.service';
import { HttpClient } from '@angular/common/http';
import { HelpDoc } from '../models/help-doc.model';

@Injectable({
  providedIn: 'root'
})
export class HeaderService extends DalbaseService {

  constructor(private httpclient: HttpClient) {
    super(httpclient);
  }

  getCartCount(UserId: number) {
    return this.executeGet('Homepage/GetCartCount?UserId=' + UserId);
  }

  getUserStoreLocation(UserId: number) {
    return this.executeGet('Homepage/GetUserStoreLocation?UserId=' + UserId);
  }
  getAccountBalance(LocationId: number) {
    return this.executeGet('Homepage/GetAccountBalance?locationid=' + LocationId);
  }
  logout() {
     return this.executeGet('Account/logout');
  }

  DeleteCartItem(UserId: number) {
    return this.executeGet('Cart/DeleteCartItem?UserId=' + UserId);
  }

  email(value:any) {
    return this.executePost('ExceptionHandler/Email', value);
  }
  onhelp() {
     return this.executeGet('Homepage/GetHelp');
    
  }
  uploadFiletoblob(file: any[]) {
    const formData: FormData = new FormData();
    formData.append('HelpDoc', file[0]);
    return this.executePost('/Attachment/uploadFile', formData);
  }
  inserthelpdoc(helpdoc: HelpDoc) {
   
    return this.executePost('HomePage/InsertHelpDoc', helpdoc);
  }
  gethelpdoc(Role?: string) {
    
    return this.executeGet('HomePage/GetHelpDoc?Role=' + Role);
  }
  gethelpdocfromblob(DocId: number) {
    var result = this.httpclient.get('api/HomePage/GetHelpDocBlob?DocId=' + DocId, { responseType: 'blob' });
    return result;
  }
  updatehelpdoc(helpdoc: HelpDoc)
  {
    return this.executePost('HomePage/UpdateHelpDoc' , helpdoc);
    }
}

export class HelpDoc {
  DocumentUrl: string;
  DocumentName: string;
  Role: string;
  _Role: string;
  UserId: number;
  Status: string;
  DocId: number;
  onEdit: boolean;
}

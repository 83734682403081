
<section class="seg-page-not-found">
  <div class="col-12 px-lg-4 mt-4">
    <div class="row align-items-center inner-bg">
      <div class="col-12 col-lg-6 col-md-12 p-0 d-none d-sm-block text-center">
        <img src="../../../assets/images/404_image.png" class="pagenotfound-image" />
      </div>
      <div class="col-12 col-lg-6 col-md-12 p-0 text-center">

        <h1>
          404
          <span>Error</span>
        </h1>
        <p class="sorry-text">
          SORRY, THE PAGE NOT FOUND
        </p>
        <p class="pn-details">
          We couldn't find that page here.
          <br />
          You can go to the <a routerLink="/">homepage</a> instead.
        </p>
      </div>

    </div>
  </div>
</section>

import { Injectable, ErrorHandler } from '@angular/core';
import { HttpErrorResponse } from '@angular/common/http';
import { CartService } from '../services/cart.service';
import { Errorlog } from '../../Models/errorlog.model';
import {LoadingService } from '../../module/loading/services/loading.service';
@Injectable()
export class GlobalErrorHandlerService implements ErrorHandler {
  err: Errorlog = new Errorlog();
  constructor(private csv: CartService, private lsvc: LoadingService) { }

  handleError(error: Error | HttpErrorResponse) {
    // your custom error handling logic
    if (error instanceof HttpErrorResponse) {
      this.err.Description = error.error;
      this.err.Message = error.name;
      this.err.Source = error.url;
      this.csv.ExceptionHandler(this.err).subscribe();
    } else {
      this.err.Description = error.stack ? error.stack:"";
      this.err.Message = error.name ? error.name:"";
      this.err.Source = 'Angular';
      
      this.csv.ExceptionHandler(this.err).subscribe();
    }

    this.lsvc.stopLoader();
  }
}

import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-addressstripe',
  templateUrl: './addressstripe.component.html',
  styleUrls: ['./addressstripe.component.scss']
})
export class AddressstripeComponent implements OnInit {

  constructor() { }

  contactaddress = '8928 Prominence Parkway, #200 Jacksonville, FL 32256';

  contactnumber = '1-800-967-9105';

  email = 'StoreHelpDesk@segrocers.com';

  ngOnInit() {
  }

}

export interface Addressstripe {
  contactaddress: any;
  contactnumber: number;
  email: string;
}

import { Component, OnInit,OnDestroy } from '@angular/core';

import { CommonserviceService } from '../../common/services/commonservice.service';
@Component({
  selector: 'app-page-not-found',
  templateUrl: './page-not-found.component.html',
  styleUrls: ['./page-not-found.component.scss']
})
export class PageNotFoundComponent implements OnInit, OnDestroy {
  
  constructor(private cmnSvc: CommonserviceService) { }

  ngOnInit() {
    this.cmnSvc.isPageNotFound.emit(true);
  }
  ngOnDestroy() {
    this.cmnSvc.isPageNotFound.emit(false);
  }
}

export class Approval {
  ApprovedBy: string;
  Submitter: string;
  ModifiedDate: Date;
  TotalCost: number;
  Status: string;
  OrderId: string;
  OrderApprovalId: number;
  CartId: number;
  orderIdDetails: OrderIdDetails[];
  UserId: number;
  TimeZone: string;
  StoreInfo: string;
  Corp: number

}
export class OrderIdDetails {
  ItemName: string;
  ItemId: number;
  Price: number;
  UnitOfMeasure: string;
  MaxAllowedToOrder: number;
  CompanyName: string;
  ItemCount: number;
  TotalPrice: string;
  ImageUrl: string;
  OnEdit = false;
  CartItemId: number;
  Status: string;
  OrderApprovalId: string;
  ApprovedBy: number;
  UserId: number;
  CategoryName: string;
  Description: string;
  CategoryId: number;
  UserCartId: number;
  ItemCode: string;
  VendorId: number;
  CategoryCode: string;
  GLAccountNo: string;
  ApprvdStatus: string[];
  _ItemCount: number;
  selectedOption: number = 1;
  Nature: string;
  StoreNumber: number;
  UserName: string;
  City: string;
  RegionName: string;
  TimeZone: string;
  BannerName: string;
  Phone: number;
  Isbulkorder: boolean;
  PriceWithTax: number;
}
export class CommentsList {
  CreatedBy: string;
  Comment: string;
  CreatedDate: Date;
}
export class Comments {
  UserId: number;
  CartId: number;
  Comment: string;
  
}

export class ApprovalPost {
  public UserId: number;
  public CategoryId: number;
  public UserCartId: number;
  public Status: string;
  public CartItemId: number;
  public isRequireApproval: boolean;
  public StoreLocationId: number;
  public Category: string;
  public FromEmailId: string;
  public StoreLocation: string;
  public PreparedUser: string;
  public Region: string;
  public StorePhone: string;
  public ItemName: string;
  public Description: string;
  public StoreManagerId: any;
  public UnitOfMeasure: string;
  public ItemCount: number;
  public Price: string;
  public BannerName: string;
  public OrderDate: string;
  public ItemId: number;
  public GLAccountNo: string;
  public Approver: number;
  public ItemCode: string;
  public CompanyName: string;
  public ImageUrl: string;
  public OrderId: string;
  public CategoryCode: string;
  public VendorId: number;
  public city: string;
  public Nature: string;
  public UserName: string;
  public Isbulkorder: boolean;
  public Bus_Unit: string;
  
}
export class DashoardReportModel {
  FiscalYear: number;
  Period: number;
  Region: number;
  Banner: string;
  ItemCode: string;
  Store: number;
  WDCode: string;
  BdgtAllctdFrom: number;
  BdgtAllctdTo: number;
  BdgtCnsmdFrom: number;
  BdgtCnsmdTo: number;
  Status: string;
  //
  UserId: number;
}

<section class="myorder-warp">
  <div class="container-fluid p-0">
    <app-breadcrumb [breadcrumb]="items"></app-breadcrumb>
    <div class="row">
      <div class="col-12 col-xl-8">
        <router-outlet></router-outlet>
      </div>
      <div class="col-12 col-xl-4 order-right-warp">
        <div class="col-12 order-right-bg mb-3">
          <div class="row price-details">
            <h2>Funds</h2>
          </div>

          <div class="col-12 price-info" *ngIf="!iscorp">
            <div class="seg-top-middle m-0" *ngFor="let cartValue of funds">
              <div class="row no-gutters" *ngIf="cartValue.CategoryName!='Smallwares' ">
                <div class="col-8" *ngIf="cartValue.CategoryName!='Store Services' && cartValue.CategoryName!='Warehouse'">
                  <h4> Remaining Budget <span class="s-size">({{cartValue.GLAccountDescription}}) </span> </h4>
                </div>
                <div class="col-8" *ngIf="(cartValue.CategoryName == 'Store Services'|| cartValue.CategoryName == 'Warehouse')&& cartValue.isInCart">
                  <h4> Remaining Budget <span class="s-size">({{cartValue.GLAccountDescription}}) </span> </h4>
                </div>
                <div class="col-4" *ngIf="(cartValue.CategoryName != 'Store Services' && cartValue.CategoryName != 'Warehouse')  || cartValue.isInCart  ">
                  <h4>
                    <span class="space-r">:</span> <span class="cons-budget badge badge-pill badge-danger">{{cartValue.RemainingFund| currency}}</span>
                    <i class="fa fa-arrow-down space-l color-r"></i>
                  </h4>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="col-12 order-right-bg">
          <div class="row price-details">
            <h2>Price Details</h2>
          </div>
          <ng-container  *ngIf="!iscorp">
            <div class="col-12 price-info" >
              <div class="row no-gutters">
                <div class="col-7"><h3>Sub Total (<span *ngIf="cartValue">{{cartValue.TotalItemCount}}</span>  Items)</h3></div>
                <div class="col-5 text-right"><h3><span *ngIf="cartValue">{{cartValue.TotalCartValue | currency}}</span></h3></div>
              </div>
              <div class="row no-gutters">
                <div class="col-7"><h3>Sales Tax</h3></div>
                <div class="col-5 text-right"><h3><span *ngIf="cartValue">{{cartValue.TaxPercent | currency}}</span></h3></div>
              </div>
              <div class="row no-gutters">
                <div class="col-7"><h3>Shipping Cost</h3></div>
                <div class="col-5 text-right"><h3><span *ngIf="cartValue">{{cartValue.ShippingCost | currency}}</span></h3></div>
              </div>

              <i class="dotted-line"></i>

              <div class="row no-gutters">
                <div class="col-7"><h3>Order Total</h3></div>
                <div class="col-5 text-right"><h3><span *ngIf="cartValue">{{cartValue.GrandTotal | currency}}</span></h3></div>
              </div>
            </div>
            </ng-container>
            <div class="col-12 price-info" *ngIf="iscorp ">
              <div class="row no-gutters">
                <div class="col-7"><h3>Sub Total </h3></div>
                <div class="col-5 text-right"><h3><span *ngIf="bulkorderfunds">{{bulkorderfunds.TotalCartValue | currency}}</span></h3></div>
              </div>
              <div class="row no-gutters">
                <div class="col-7"><h3>Sales Tax</h3></div>
                <div class="col-5 text-right"><h3><span *ngIf="bulkorderfunds">{{bulkorderfunds.TaxPercent | currency}}</span></h3></div>
              </div>
              <div class="row no-gutters">
                <div class="col-7"><h3>Shipping Cost</h3></div>
                <div class="col-5 text-right"><h3><span *ngIf="bulkorderfunds">{{bulkorderfunds.ShippingCost | currency}}</span></h3></div>
              </div>

              <i class="dotted-line"></i>

              <div class="row no-gutters">
                <div class="col-7"><h3>Order Total</h3></div>
                <div class="col-5 text-right"><h3><span *ngIf="bulkorderfunds">{{bulkorderfunds.GrandTotal | currency}}</span></h3></div>
              </div>
            </div>

        </div>
      </div>

    </div>
  </div>
</section>
<app-addressstripe></app-addressstripe>
<div class="order-dialog">
  <p-dialog header="" [(visible)]="showConf" [modal]="true" [responsive]="true" [style]="{width: '450px'}">
    <p>
      Order exceed maximum total amount/maximum budget and requires approval
    </p>
    <p-footer class="btn-footer">
      <button type="button" pButton icon="pi pi-check" (click)="showConfirm()" label="Ok" class="btn main-btn">Ok</button>
      <button (click)="showConf=false" class="btn main-btn-white">Cancel</button>
    </p-footer>
  </p-dialog>
</div>

<div class="order-dialog">
  <p-dialog header="" [(visible)]="showConfnew" [modal]="true" [responsive]="true" [style]="{width: '450px'}">
    <p>
      Order from store services requires approval
    </p>
    <p-footer class="btn-footer">
      <button type="button" pButton icon="pi pi-check" (click)="showConfirmnew()" label="Ok" class="btn main-btn">Ok</button>
      <button (click)="showConfnew=false" class="btn main-btn-white">Cancel</button>
    </p-footer>
  </p-dialog>
</div>


import { Injectable, EventEmitter } from '@angular/core';
import { Subject } from 'rxjs';
import { Userdetails } from '../../Models/userdetails';
import { Cart } from '../../Models/cart';
import { UserStoreLocation } from '../../Models/user-store-location.model';
import { Selectedstorelocations } from '../../Models/selectedstorelocations.model';
import { Category } from '../../Models/category';
import { Fund } from '../../Models/fund';
import { Checkoutmodel } from '../../Models/checkoutmodel';
import * as _ from 'lodash';
import * as moment from 'moment-timezone';
import { AzureADUserDetailsModel } from '../../Models/azure-aduser-details-model';
import { Bulkorder } from '../../module/myorderadddetails/model/bulkorder.model';
import { DashboardReportParam } from '../../module/dashboardreport/models/dashboardreport.model';
import { MenuList } from 'src/app/module/navbar/model/navbar';
@Injectable({
  providedIn: 'root'
})
export class CommonserviceService {
  public menuItems: EventEmitter<MenuList[]> = new EventEmitter();
  public isreportapprovactive: boolean = false;
  public isapprovaldashboardactive: boolean = false;
  public reportfilter: DashboardReportParam;
  public isbulkprocess: boolean = false;
  public searchitem: string;
  public syncedGlAccno: string;
  public UserDetails: Userdetails;
  public userStoreLocation: UserStoreLocation[];
  public selectedstorelocations: Selectedstorelocations;
  loaderperc: EventEmitter<string> = new EventEmitter();
  public bulkorder = new Subject<Bulkorder[]>();
  public CartCount = new Subject<number>();
  public TotlCartValue = new Subject<Cart>();
  public FinalCartValue: Cart;
  public CartGrandTotal: number;
  public Category: Category;
  public Authenticate: string;
  public AllocatedFund: Fund[];
  public Fund: EventEmitter<Fund[]> = new EventEmitter();
  toast: EventEmitter<Toast> = new EventEmitter();
  productname: EventEmitter<string> = new EventEmitter();
  public isRequireApproval: boolean;
  public checkOut: Checkoutmodel;
  public OrderedItems: any;
  public locationChangeEmitter: EventEmitter<boolean> = new EventEmitter();
  public ActiveStoreLocation: EventEmitter<boolean> = new EventEmitter();
  public isPageNotFound: EventEmitter<boolean> = new EventEmitter();
  roleConfig: string;
  baseUrl: string;
  onloadNavbar: EventEmitter<any> = new EventEmitter();
  UserAdInformation: AzureADUserDetailsModel;
  officesupplyCatId: number;
  navigateEmail: boolean;
  isUploadingBudget: boolean = false;
  isUploadingCategory: boolean = false;
  isUploadingItem: boolean = false;
  isUploadingVendor: boolean = false;

  constructor() { }

  showToast(severity: string, summary: string, detail: string) {
    this.toast.emit({ severity, summary, detail, life: severity === "error" ? 15000 : 2000, closable: true });
  }
  showproductname(productname: string) {
    this.productname.emit(productname);
  }

  checkRole(module: string) {
    
    const roleTable = 'dashboard,admin,report,uploadImage,orderonbehalf,pdflink,storeservice,fullorderhistory,Button,Cart,MyOrders,Favorites,ManageOrder';
    if (!roleTable.includes(module))
      return true;

    const val = this.roleConfig ? this.roleConfig.split(',') : null;
    if (val) {
      if (val.findIndex(a => a === module) >= 0) {
        return true;
      } else {
        return false;
      }
    }
  }

  convertToTimezone(timezone: string) {
    switch (timezone) {
      case 'Eastern': return moment.tz(new Date(), 'US/Eastern').format('YYYY-MM-DD HH:mm:ss');
      case 'Pacific': return moment.tz(new Date(), 'US/Pacific').format('YYYY-MM-DD HH:mm:ss');
      case 'Central': return moment.tz(new Date(), 'US/Central').format('YYYY-MM-DD HH:mm:ss');
    }
  }

}

export interface Toast {
  severity: string;
  summary: string;
  detail: string;
  life: number;
  closable: boolean;
}
export interface Product {
  productname: string;
}

import { Component, OnInit, Input } from '@angular/core';
//import { MenuItem } from 'primeng/components/common/menuitem';
import { MenuItem } from 'primeng/api';
import { Router, NavigationEnd } from '@angular/router';

@Component({
  selector: 'app-breadcrumb',
  templateUrl: './breadcrumb.component.html',
  styleUrls: ['./breadcrumb.component.scss']
})
export class BreadcrumbComponent implements OnInit {

  constructor() { }
  @Input('breadcrumb') items: MenuItem[] = [];
  ngOnInit() {

  }

}

import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FileuploadComponent } from '../fileupload.component';
import { FileUploadModule } from 'primeng/fileupload';


@NgModule({
  declarations: [FileuploadComponent],
  imports: [
    CommonModule,
    FileUploadModule
  ],
  exports: [FileuploadComponent]
})
export class FileuploadModule { }

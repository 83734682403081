import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { AuthGuardGuard } from './common/auth-guard.guard';

import { OrderhistoryComponent } from './module/orderhistory/orderhistory/orderhistory.component';
import { MailapprovalComponent } from './module/approvaldashboard/mailapproval/mailapproval.component';

const routes: Routes = [
  {
    path: 'home',
    loadChildren: () => import('./module/home/home.module').then(m => m.HomeModule),
    canActivate: [AuthGuardGuard],
    data: { showSearch: true }
    // component: HomeComponent
  },
  {
    path: 'myorder',
    loadChildren: () => import('./module/myorder/myorder.module').then(m => m.MyorderModule),
    canActivate: [AuthGuardGuard]
    // component: MyorderComponent
  },
  {
    path: 'orderhistory',
    // loadChildren: () => import('./module/home/home.module').then(m => m.HomeModule)
    component: OrderhistoryComponent
  },
  {
    path: 'item', loadChildren: () => import('./module/itemlist/itemlist.module').then(m => m.ItemlistModule),
    data: { showSearch: true }
  },
  {
    path: 'myorderplaced',
    loadChildren: () => import('./module/myorderplaced/myorderplaced.module').then(m => m.MyorderplacedModule)
  },
  {
    path: 'productdetail',
    loadChildren: () => import('./module/productdetails/productdetails.module').then(m => m.ProductdetailsModule),
    data: { showSearch: true },
    canActivate: [AuthGuardGuard]
  },
  {
    path: 'searchs',
    loadChildren: () => import('./module/search/search.module').then(m => m.SearchModule),
    data: { showSearch: true },
    canActivate: [AuthGuardGuard]
  },
  {
    path: 'dashboard',
    loadChildren: () => import('./module/approvaldashboard/approvaldashboard.module').then(m => m.ApprovaldashboardModule),
    canActivate: [AuthGuardGuard]
  },
  {
    path: 'dashboardreport',
    loadChildren: () => import('./module/dashboardreport/dashboardreport.module').then(m => m.DashboardreportModule),
    canActivate: [AuthGuardGuard]
  }, 
  {
    path: 'admin',
    loadChildren: () => import('./module/admin/admin.module').then(m => m.AdminModule),
    canActivate: [AuthGuardGuard]
  },
  {
    path: 'logout',
    loadChildren: () => import('./module/logout/logout.module').then(m => m.LogoutModule),
   
  },
  {
    path: 'mailapprove/:CartId/:isBulk',
    component: MailapprovalComponent,
    canActivate: [AuthGuardGuard]
  },
  {
    path: '404',
    loadChildren: () => import('./page-not-found/page-not-found.module').then(m => m.PageNotFoundModule)
  },
  { path: '', redirectTo: 'home', pathMatch: 'full' },
  { path: '**', redirectTo: '404', pathMatch: 'full' }
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }

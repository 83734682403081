import { Pipe, PipeTransform} from '@angular/core';
import { ProductStatus } from '../Models/approval-dashboard';
import { ProductStatusIcon, PipeOptions } from './product-status-pipe.model';
@Pipe({
  name: 'ProductStatusPipe'
})

export class ProductStatusPipe implements PipeTransform {

  transform(value: ProductStatus, arg: PipeOptions): any {

    switch (arg) {
      case PipeOptions.class:
        return this.getClass(value);
      case PipeOptions.label:
        return this.getLabel(value);
      case PipeOptions.icon:
        return this.getIcon(value);
      default:
        return '';
    }
  }

  getLabel(value: ProductStatus) {
    switch (value) {
      case ProductStatus.Approved:
        return 'Approved';
      case ProductStatus.Canceled:
        return 'Canceled';
      case ProductStatus.OnHold:
        return 'On Hold';
      case ProductStatus.Pending:
        return 'Pending';
      default:
        return '';
    }
  }

  getClass(value: ProductStatus): string {
    switch (value) {
      case ProductStatus.Approved:
        return 'approved';
      case ProductStatus.Canceled:
        return 'canceled';
      case ProductStatus.OnHold:
        return 'onhold';
      case ProductStatus.Pending:
        return 'pending';
      default:
        return '';
    }
  }

  getIcon(value: ProductStatus): string {
    switch (value) {
      case ProductStatus.Approved:
        return ProductStatusIcon.approved;
      case ProductStatus.Canceled:
        return ProductStatusIcon.canceled;
      case ProductStatus.OnHold:
          return ProductStatusIcon.onhold;
      case ProductStatus.Pending:
          return ProductStatusIcon.pending;
      default:
        return '';
    }
  }
}





import { Component, OnInit } from '@angular/core';
import { TableModule } from 'primeng/table';
import { OrderhistoryService } from '../service/orderhistory.service';
import { Orderhistory, SearchOrderHistoryModel, GetOrderHisoryModel, ApprovalReportModel } from '../model/orderhistory';
import * as _ from 'lodash';
import { CommonserviceService } from 'src/app/common/services/commonservice.service';
import { CartService } from 'src/app/common/services/cart.service';
import { MenuItem } from 'primeng/api';
import { Router } from '@angular/router';
import { Myorderdetails } from '../../officesupplies/model/officesupplies.model';
import { Fund } from 'src/app/Models/fund';
import { DatePipe } from '@angular/common'
import * as moment from 'moment';
import { GlobalVariable } from '../../../common/globalvariable';

import { StorenumberpipeModule } from '../../../pipes/storenumberpipe/storenumberpipe/storenumberpipe.module';
@Component({
  selector: 'app-orderhistory',
  templateUrl: './orderhistory.component.html',
  styleUrls: ['./orderhistory.component.scss']
})
export class OrderhistoryComponent implements OnInit {
 
  sortby: string = "desc";
  field: string = "CreatedTS,WDCode";
  result: boolean = true;
  role: string;
  date: Date;
  skip: number;
  TotalRecords: number;
  orderhistory: GetOrderHisoryModel = new GetOrderHisoryModel();
  bulkorderlist: Orderhistory[];
  bulkorderdetails: Orderhistory[];
  _clonebulkorderlist: Orderhistory[];
  searchmodel: SearchOrderHistoryModel = new SearchOrderHistoryModel();
  bulksales: Orderhistory[];
  approvalreport: ApprovalReportModel = new ApprovalReportModel();
  searchquery: string;
  showfilter: boolean = true;
  isAddNote: boolean = false;
  comment: string;

  constructor(private orderhistoryServices: OrderhistoryService,
    private cmnsvc: CommonserviceService, private cartsvc: CartService, private router: Router, private datepipe: DatePipe) { }
  notes = false;
  storefilter: number;
  selectedValue: string = 'orderhistory';
  sales: Orderhistory[];
  orderid: string;
  cartId: number;
  salesItem: any[];
  items: MenuItem[];
  text = 'Reorder';
  commentsList: any[];
  fund: Fund[];
  orderList: Myorderdetails[];
  cols: any[];
  coloumns: any[];
  hasFund: boolean;
  hasrole: boolean;
  totalRecords: number = 200;
  defaultImage = GlobalVariable.Default_Url;

  

  ngOnInit() {
    if (this.cmnsvc.isreportapprovactive) { this.showfilter = false; } else { this.showfilter = true; }
    if (this.cmnsvc.UserDetails) {
      this.hasrole = this.cmnsvc.UserDetails.AzureAdRole != null ? true : false;
      this.role = this.cmnsvc.UserDetails.Role;
    }
    this.fund = this.cmnsvc.AllocatedFund;
    // this.getOrderHistory();
    this.cmnsvc.locationChangeEmitter.subscribe((data: any) => {
      this.getOrderHistory();
    });
    this.items = [
      { label: 'Home', routerLink: ['/home'] },
      { label: 'Order History', },
    ];
    this.cols = [
      { field: 'OrderId', header: 'Order ID#' },
      { field: 'WDCode', header: 'WDCode#' },
      { field: 'ImageUrl', header: 'Image' },
      { field: 'Description', header: 'Item Name' },
      { field: 'Category', header: 'Category' },
      { field: 'ModifiedTS', header: 'Date' },
      { field: 'OrderedBy', header: 'Ordered by' },
      { field: 'StoreLocation', header: 'Store' },
      { field: 'Price', header: 'Order Value' },
      { field: 'Status', header: 'Order Status' },
      { field: 'Status', header: 'Notes' },
      { field: '', header: 'CancelOrder' },
      { field: '', header: 'Action' },

    ];
    this.coloumns = [
      { field: 'OrderId', header: 'Order ID#' },
      { field: 'WDCode', header: 'WDCode#' },
      { field: 'ImageUrl', header: 'Image' },
      { field: 'Description', header: 'Item Name' },
      { field: 'Category', header: 'Category' },
      { field: 'ModifiedTS', header: 'Date' },
      { field: 'OrderedBy', header: 'Ordered by' },
      { field: 'Price', header: 'Order Value' },
      { field: 'Status', header: 'Order Status' },
      //{ field: '', header: 'CancelOrder' },
      { field: 'StoreLocation', header: 'StoreLocation' },
      { field: 'ItemCount', header: 'Quantity' },
      //{ field: 'Status', header: 'Notes' },
      //{ field: '', header: 'CancelOrder' },
      //{ field: '', header: 'Action' },

    ];
  }
  updateUrl(products: Orderhistory) {
    products.ImageUrl = 'assets/img/no-image.jpg';
  }
  showNotes() {
    this.notes = true;
  }
  recentView(product) {
      if (this.cmnsvc.checkRole('storeservice') && product.Category === 'Store Services') {
        this.router.navigate(['productdetail'], { queryParams: { productId: product.ItemId, productName: product.ItemName, VendorId: product.VendorId, CategoryId: product.CategoryId } });
      }
      else if (product.Category !== 'Store Services') {
        this.router.navigate(['productdetail'], { queryParams: { productId: product.ItemId, productName: product.ItemName, VendorId: product.VendorId, CategoryId: product.CategoryId } });
      }   
  }
  GetNote(CartId) {
    this.cartId = CartId;
    this.orderhistoryServices.getcoments(CartId).subscribe((coments: any) => {
      this.commentsList = coments;
      this.showNotes();
    });
  }

  async getOrderHistory(skip: number = 0, search: string = "") {
    var IsSearch: boolean = search != "" ? true : false
    this.skip = skip;

    var data: any = {
      UserId: this.cmnsvc.UserDetails ?
        this.cmnsvc.UserDetails.UserId : null, StoreNumber: this.cmnsvc.selectedstorelocations.StoreNumber, IsSearch: IsSearch, Skip: skip.toString(), SearchQuery: search, SortBy: this.field, SortOrder: this.sortby, searchBudgetModel: this.searchmodel
    }
    this.orderhistory.UserId = this.cmnsvc.UserDetails ?
      this.cmnsvc.UserDetails.UserId : null;
    this.orderhistory.StoreNumber = this.cmnsvc.selectedstorelocations.StoreNumber;
    this.orderhistory.SortBy = this.field;
    this.orderhistory.SortOrder = this.sortby;
    this.orderhistory.Skip = skip;
    this.orderhistory.SearchQuery = search;

 
    if (this.cmnsvc.checkRole('fullorderhistory') && this.cmnsvc.UserDetails.Role == 'Admin') {
    
      this.orderhistory.Fullorderhistory = 1;
    
    }
    else {
      //mn	
      this.orderhistory.Fullorderhistory = 0;
  
    }
    //mn


    for (let key of Object.keys(this.orderhistory)) {
      if (key == "Description" || key == "StoreLocation" || key == "Price" || key == "OrderId" || key == "Date" || key == "OrderedBy" || key == "Category" || key =="WDCode") {
        if (this.orderhistory[key] != "") {
          IsSearch = true;

        }
      }

    }
    this.orderhistory.IsSearch = IsSearch;
    this.orderhistoryServices.getOrderHistory(this.orderhistory).subscribe((lcdata: any) => {
      // debugger
      //window.scroll(0, 0);
      if (lcdata && lcdata.length > 0) {
        const string = "warehouse";
        if (this.orderhistory.WDCode == undefined || this.orderhistory.WDCode == '') {
          if (this.orderhistory.Category == '' || this.orderhistory.Category == undefined) {
            this.result = true;
          }
          else {
            if (string.includes(this.orderhistory.Category) == true) {
              this.result = true;
            }
            else {
              this.result = true;
            }
          }
        }
        else
          if (this.orderhistory.WDCode != undefined || this.orderhistory.WDCode != '') {
            if (this.orderhistory.Category == '' || this.orderhistory.Category == undefined) {
              this.result = true;
            }
            else {
              if (string.includes(this.orderhistory.Category) == true) {
                this.result = true;
              }
              else {
                this.result = false;
              }
            }
          }
        //this.result = true;
        this.sales = this.processData(lcdata);
        _.each(this.sales, (items) => {
          if (items.ItemCount == 0) {
            items.ItemCount = 1;

            if (this.cmnsvc.selectedstorelocations && !this.cmnsvc.selectedstorelocations.Approver) { items.isactive = false; }
          }
          items.hasbudget = this.cmnsvc.AllocatedFund.find(a => a.CategoryId == items.CategoryId && a.GlAccno.trim() == items.GLAccountno.trim()) ? true : false;
          if (items.Category != "Store Services" && items.Category != "Warehouse")
            items.isactive = true;

          if (items.WDCode == 0) {
            items.WDCode=''
          }
        });
      } else {
        this.result = false;
        this.totalRecords = 0;
      }

    });

   

  }
  async getBulkOrderHistory(skip: number = 0, search: string = "") {


    this.orderhistoryServices.getBulkOrderHistory(this.cmnsvc.UserDetails ?
      this.cmnsvc.UserDetails.UserId : null, this.cmnsvc.selectedstorelocations.StoreNumber, skip, search != "" ? true : false, search, this.field, this.sortby).subscribe((lcdata: any) => {
        // debugger
        window.scroll(0, 0);
        if (lcdata && lcdata.length > 0) {
          this.result = true;
          this.bulksales ? this.bulksales.splice(0, this.bulksales.length) : this.bulksales;
          this.bulksales = this.processData(lcdata);
          _.each(this.bulksales, (items: Myorderdetails) => {
            if (items.ItemCount == 0) {
              items.ItemCount = 1;
              items.isactive = this.cmnsvc.AllocatedFund.find(a => a.CategoryId == items.CategoryId) ? true : false;
              if (this.cmnsvc.selectedstorelocations && !this.cmnsvc.selectedstorelocations.Approver)
                items.isactive = false;

              if (items.Category == "Store Services" || items.Category == "Warehouse")
                items.isactive = false;

            }
          });
        } else {
          this.result = false;
          this.totalRecords = 0;
        }
        this.bulkorderlist ? this.bulkorderlist.splice(0, this.bulkorderlist.length) : this.bulkorderlist;
        this.bulkorderlist = this.bulksales;
      });
    //  this._clonebulkorderlist = this.bulkorderlist;
    //  _.each(this.bulkorderlist, (bulkorder: Orderhistory) => {
    //    bulkorder.TotalPrice = 0;
    //    bulkorder.TotalStoreNumber = 0;
    //    bulkorder.TotalItemCount = 0
    //    _.each(this.sales, (iterate: Orderhistory) => {
    //      if (bulkorder.OrderId == iterate.OrderId) {

    //        bulkorder.TotalPrice += iterate.Price;
    //        bulkorder.TotalStoreNumber++;
    //        bulkorder.TotalItemCount += iterate.ItemCount
    //      }
    //    })
    //  })


    //});

  }

  async loadLazy(event: any) {
    this.field = event.sortField ? event.sortField : "CreatedTS";
    this.sortby = event.sortOrder == 1 ? "desc" : "asc";
    if (!this.cmnsvc.isreportapprovactive) {

      if (event.filters && event.filters["global"]) {
        this.getOrderHistory(event.first, event.filters["global"]["value"]);

      } else
        await this.getOrderHistory(event.first);
    }
    else {
      this.searchquery = (event.filters && event.filters["global"]) ? event.filters["global"]["value"] : null;
      this.skip = event.first ? event.first : 0;
      this.GetApprovalReportHistory(event)
    }

  }

  async loadLazybulk(event: any) {
    this.field = event.sortField ? event.sortField : "CreatedTS";
    this.sortby = event.sortOrder == 1 ? "desc" : "asc";
    if (event.filters && event.filters["global"]) {
      this.getBulkOrderHistory(event.first, event.filters["global"]["value"]);

    } else
      await this.getBulkOrderHistory(event.first);

  }

  addToCart(products: Orderhistory) {
    if (!this.orderList)
      this.orderList = [];
    this.orderList.push(products);
    this.calUsedFund(products);
    this.hasFund = true;
    if (this.hasFund) {
      this.sales.find(a => a.ItemId === products.ItemId) ? this.sales.find(a => a.ItemId === products.ItemId).OrderedItem = -1 : null;
      this.orderhistoryServices.getreorderdetails(products.ItemId, this.cmnsvc.UserDetails.UserId).subscribe((atcdata: any) => {
        const Itemcount = atcdata.Itemcartcount;
        if (Itemcount === 0) {


          this.cartsvc.addtoCart(this.cmnsvc.UserDetails.UserId, products.ItemId, products.CategoryId, 1, products.Price, products.VendorId).subscribe((count: any) => {

            this.cmnsvc.CartCount.next(count.CartCount);
            this.cmnsvc.showToast('success', 'Success Message', 'Successfully Added to Cart');


          });
        } else {
          this.cmnsvc.showToast('warn', 'Success Message', 'Already addeded to cart');
          this.router.navigateByUrl('/myorder');
        }
      });
    }
    else {
      this.cmnsvc.showToast('error', 'Warning', 'No Funds to increase this item');
      this.orderList.splice(this.orderList.findIndex(a => a.ItemListId === products.ItemListId), 1);
    }
  }

  processData(hist: Orderhistory[]) {
    this.totalRecords = hist[0].TotalRecords;
    return _.map(hist, (his: Orderhistory) => {
      switch (his.Status.toLowerCase()) {
        case 'a': his.Status = 'Approved'; break;
        case 'd': his.Status = 'Denied'; break;
        case 'p': his.Status = 'Pending'; break;
        case 'c': his.Status = 'Cancelled'; break;
        case 'f': his.Status = 'Completed'; break;

      }
      return his;
    })
  }

  calUsedFund(product: Myorderdetails) {

    _.each(this.fund.filter(a => a.CategoryId === product.CategoryId), (odr) => {
      let fnd = 0;
      let hsVal = false;
      _.each(this.orderList.filter(a => a.CategoryId === odr.CategoryId), (val) => {
        const itVlue = val.ItemCount * (+val.Price);
        fnd = fnd + itVlue;
        fnd = fnd + (itVlue * (val.EstimateShippingCost / 100));
        hsVal = true;
        odr.UserCartItemId = val.UserCartItemId;
      });

      odr.UsedFund = fnd + (fnd * (this.cmnsvc.selectedstorelocations.TaxPercent / 100));
      odr.RemainingFund = odr.AllocatedFund - odr.UsedFund;
      if (hsVal) {
        odr.IsRequireAproval = odr.RemainingFund > 0 ? false : true;
      } else {
        odr.IsRequireAproval = false;
      }
      this.hasFund = true;
      if (odr.IsRequireAproval) {
        this.hasFund = false;
      }
    });
  }
  async CancelOrder(products, isbulkorder: boolean) {

    products.ImageUrl = "";
    var cancelorder = [];
    if (isbulkorder) {
      await this.openGroup(products);
      cancelorder = this.bulkorderdetails.filter(x => x.Status == "Approved");
      products.PSOrderId = this.bulkorderdetails[0].PSOrderId;
    }
    else {
      var res: any = await this.orderhistoryServices.GetPSOrderId(products.OrderId, products.ItemId, products.UserCartId, products.VendorId).toPromise();
      products.PSOrderId = res.PSOrderId;
      cancelorder.push(products);
    }


    if (products.PSOrderId) {

      this.orderhistoryServices.Cancelpsorderid(cancelorder).subscribe((data: any) => {
        if (data == true) {
          isbulkorder ? this.getBulkOrderHistory() : this.getOrderHistory(this.skip);
          this.cmnsvc.showToast('success', 'Success Message', 'Succcessfully cancelled the order');
        }
        else {
          this.cmnsvc.showToast('warn', 'warning Message', 'Something Went Wrong While Cancelling the Process');
        }
      });
    }
    else {
      this.orderhistoryServices.cancelorder(cancelorder, this.cmnsvc.UserDetails.UserId).subscribe(async (data: any) => {
        if (data == true) {
          isbulkorder ? this.getBulkOrderHistory() : this.getOrderHistory(this.skip);
          this.cmnsvc.showToast('success', 'Success Message', 'Succcessfully cancelled the order');
          var res: any = await this.orderhistoryServices.GetBulkOrderDetails(products.OrderId).toPromise();
          res = this.processData(res);
          this.bulkorderdetails = res;
        }
        else {
          this.cmnsvc.showToast('warn', 'warning Message', 'Something Went Wrong While Cancelling the Process');
        }
      });
    }
  }

  sync() {
    this.orderhistoryServices.SyncReferenceId().subscribe((data: any) => {
      if (data == true) {
        this.getOrderHistory();
        this.cmnsvc.showToast('success', 'Success Message', 'Succcessfully Synced');
      }
      else {
        this.cmnsvc.showToast('warn', 'Warning Message', 'No Sync File Was Found');
      }
    });
  }
  refresh() {
    if (this.cmnsvc.isreportapprovactive) {
      this.getOrderHistory();
      this.showfilter = true;
      this.cmnsvc.isreportapprovactive = false;
    }
    else {
      this.getBulkOrderHistory();
    }
  }
  search() {
    if (this.orderid) {

      this.getBulkOrderHistory();
    }
  }
  async openGroup(list) {
    // this.bulkorderdetails = this.sales.filter(x => x.OrderId == list.OrderId)
    var OrderId = list.OrderId ? list.OrderId : list.data.OrderId;
    var res: any = await this.orderhistoryServices.GetBulkOrderDetails(OrderId).toPromise();
    res = this.processData(res);
    this.bulkorderdetails = res;

  }
  onfilter(event?, field?) {
    if (field == "Description") {
      this.orderhistory.Description = event;

    }

    if (field == "Category") {
      this.orderhistory.Category = event

    }

    if (field == "ModifiedTS") {
      this.orderhistory.Date = moment(this.date).format('DD/MM/YYYY').toString();

    }

    if (field == "OrderedBy") {
      this.orderhistory.OrderedBy = event

    }
    if (field == "OrderId") {
      this.orderhistory.OrderId = event

    }
    if (field == "Price") {
      this.orderhistory.OrderValue = event

    }
    if (field == "StoreLocation") {
      this.orderhistory.StoreLocation = event

    }
    if (field == "WDCode") {
     this.orderhistory.WDCode = event
    
    }
    if (!this.cmnsvc.isreportapprovactive) {
      setTimeout(() => {
        this.getOrderHistory(event.first, "");
      }, 1000);
    }
    else {
      this.GetApprovalReportHistory();
    }


  }
  clear(event) {
    this.date = null;
    this.orderhistory.Date = null;
    this.getOrderHistory(event.first, "");
  }
  GetApprovalReportHistory(event?) {
    this.storefilter = this.cmnsvc.reportfilter.Store;
    this.approvalreport.Banner = this.cmnsvc.reportfilter.Banner;
    this.approvalreport.BdgtAllctdFrom = this.cmnsvc.reportfilter.BdgtAllctdFrom;
    this.approvalreport.BdgtAllctdTo = this.cmnsvc.reportfilter.BdgtAllctdTo;
    this.approvalreport.BdgtCnsmdFrom = this.cmnsvc.reportfilter.BdgtCnsmdFrom;
    this.approvalreport.BdgtCnsmdTo = this.cmnsvc.reportfilter.BdgtCnsmdTo;
    this.approvalreport.FiscalYear = this.cmnsvc.reportfilter.FiscalYear;
    this.approvalreport.Period = this.cmnsvc.reportfilter.Period;
    this.approvalreport.Banner = this.cmnsvc.reportfilter.Banner;
    this.approvalreport.Region = this.cmnsvc.reportfilter.Region;
    this.approvalreport.ItemCode = this.cmnsvc.reportfilter.ItemCode;
    this.approvalreport.WDCode = this.cmnsvc.reportfilter.WDCode;
    this.approvalreport.Store = this.cmnsvc.reportfilter.Store;
    this.approvalreport.Status = this.cmnsvc.reportfilter.Status;
    this.approvalreport.SortBy = this.field ? this.field : "CreatedTS";
    this.approvalreport.SortOrder = this.sortby ? this.sortby : "desc";
    this.approvalreport.OrderedBy = this.orderhistory.OrderedBy;
    this.approvalreport.Description = this.orderhistory.Description;
    this.approvalreport.Date = this.orderhistory.Date;
    this.approvalreport.OrderId = this.orderhistory.OrderId;
    this.approvalreport.OrderValue = this.orderhistory.OrderValue;
    this.approvalreport.StoreLocation = this.cmnsvc.reportfilter.Store ? this.cmnsvc.reportfilter.Store.toString() : this.orderhistory.StoreLocation;
    this.approvalreport.SearchQuery = this.searchquery ? this.searchquery : "";
    this.approvalreport.IsSearch = this.approvalreport.SearchQuery.length > 0 ? true : false;
    this.approvalreport.Skip = this.skip;
    for (let key of Object.keys(this.orderhistory)) {
      if (key == "Description" || key == "StoreLocation" || key == "Price" || key == "OrderId" || key == "Date" || key == "OrderedBy") {
        if (this.orderhistory[key] != "") {
          this.approvalreport.IsSearch = true;

        }
      }
    }
    this.orderhistoryServices.GetApprovalReportHistory(this.approvalreport).subscribe((data: any) => {
      if (data && data.length > 0) {
        this.result = true;
        this.sales = this.processData(data);
        _.each(this.sales, (items: Myorderdetails) => {
          if (items.ItemCount == 0) {
            items.ItemCount = 1;
            items.isactive = this.cmnsvc.AllocatedFund.find(a => a.CategoryId == items.CategoryId) ? true : false;
            if (this.cmnsvc.selectedstorelocations && !this.cmnsvc.selectedstorelocations.Approver) { items.isactive = false; }
          }
          if (items.Category != "Store Services" && items.Category != "Warehouse")
            items.isactive = true;
        });
      } else {
        this.result = false;
        this.totalRecords = 0;
      }


    });
  }
  onClickAddNote() {
    if (this.isAddNote == true) {
      this.isAddNote = false
    }
    else {
      this.isAddNote = true;
    }

  }
  AddNote() {
    if (this.comment)
      this.comment = this.comment.trim();
    if (this.comment && this.comment.length > 0) {
      this.orderhistoryServices
        .insertcomments(this.cmnsvc.UserDetails.UserId, this.comment, this.cartId)
        .subscribe((reply: any) => {
          this.comment = '';
          this.isAddNote = false;
          this.cmnsvc.showToast('success', 'Success Message', 'Successfully Added Note');
          this.GetNote(this.cartId);
        });
    }
    else {
      this.cmnsvc.showToast('warn', 'Warning Message', 'No Notes to Add');
    }
  }

  deleteComments(CommentId) {
    this.orderhistoryServices.DeleteComments(CommentId).subscribe((data: any) => {
      if (data == true) {
        this.cmnsvc.showToast('success', 'Success Message', 'Deleted Successfully');
        this.GetNote(this.cartId);
      }
    });
  }

}



// export interface Car {
//  orderId: number;
//  storeidname: any;
//  date: string;
//  approvedby: string;
//  ordervalue: number;
// }

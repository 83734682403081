import { Pipe,  PipeTransform} from '@angular/core';
import { OrderStatus } from '../Models/approval-dashboard';
import { PipeOptions, OrderStatusIcon } from './order-status-pipe.model';

@Pipe({
  name: 'orderStatus'
})


export class OrderStatusPipe implements PipeTransform {

  transform(value: OrderStatus, arg: any): any {

    switch (arg) {
      case PipeOptions.class:
        return this.getClass(value);
      case PipeOptions.label:
        return this.getLabel(value);
      case PipeOptions.icon:
        return this.getIcon(value);
      default:
        return '';
    }
  }

  getLabel(value: OrderStatus) {
    switch (value) {
      case OrderStatus.Approved:
        return 'Approved';
      case OrderStatus.Canceled:
        return 'Canceled';
      case OrderStatus.OnHold:
        return 'On Hold';
      case OrderStatus.Pending:
        return 'Pending';
      default:
        return '';
    }
  }

  getClass(value: OrderStatus): string {
    switch (value) {
      case OrderStatus.Approved:
        return 'approved';
      case OrderStatus.Canceled:
        return 'canceled';
      case OrderStatus.OnHold:
        return 'onhold';
      case OrderStatus.Pending:
        return 'pending';
      default:
        return '';
    }
  }

  getIcon(value: OrderStatus): string {
    switch (value) {
      case OrderStatus.Approved:
        return OrderStatusIcon.approved;
      case OrderStatus.Canceled:
        return OrderStatusIcon.canceled;
      case OrderStatus.OnHold:
          return OrderStatusIcon.onhold;
      case OrderStatus.Pending:
          return OrderStatusIcon.pending;
      default:
        return '';
    }
  }
}


export enum ProductStatusIcon {
  approved = 'fa fa-check',
  canceled = 'fa fa-ban',
  pending = 'fa fa-exclamation',
  onhold = 'fa fa-pause-circle'
}
export enum PipeOptions {
  label = 'label',
  class = 'class',
  icon = 'icon'
}

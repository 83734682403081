<app-topband [fund]="fund"></app-topband>
<section class="seg-header">
  <div class="container-fluid">
    <div class="row">
      <div class="col-8 col-md-6 col-lg-3" routerLink="/">
        <img src="../../../assets/images/seg-web-logo.png" class="seg-main-logo" title="Home" />
        <img src="../../../assets/images/seg-mobile-logo.png" class="seg-main-logo-mob" title="Home" />
      </div>
      <div class="col-lg-3 d-none d-lg-block">
        <input type="text" placeholder="Search" [(ngModel)]="search" (keyup)="onKeydown($event)" *ngIf="isShowSearch" trim />
        <span class="fa fa-search search-icon" (click)="SearchFun(search)" *ngIf="isShowSearch"></span>
      </div>
      <div class="col-4 col-md-6 col-lg-6">
        <div class="row">
          <div class="col-md-5 p-0 location-button d-none d-lg-block">
            <i class="fa fa-map-marker"></i>
            <p-dropdown [options]="locationitems" [(ngModel)]="selectedstorelocations" (onChange)="onchange($event)" filter="true"></p-dropdown>
            <i class="fa fa-exclamation-triangle exclamation" aria-hidden="true" *ngIf="!hasAppr" title="No Approver has been assigned.Please contact administrator"></i>
          </div>
          <div class="col-lg-7 col-md-12 pl-0">
            <div class="row">
              <div class="col-md-4 pl-0 mo-button d-none d-sm-block">
                <button type="button" pButton (click)="cmenu.toggle($event)">
                  My Orders <i class="fa fa-angle-down"></i>
                </button>
                <p-menu #cmenu [popup]="true" [model]="myorderitems"></p-menu>
              </div>
              <div *ngIf="cartCount>0;then content else other_content">here is ignored</div>
              <ng-template #content>
                <div class="col-lg-3 col-md-4">
                  <div class="seg-head-cart" routerLink="/myorder">
                    <img src="../../../assets/images/bag-icon.png" />
                    <span class="badge badge-danger">{{cartCount}}</span>
                  </div>
                  <div class="d-block d-lg-none mob-search-icon" *ngIf="isShowSearch">
                    <span class="fa fa-search" (click)="showDialog()"></span>
                  </div>
                  <div class="search-popup">
                    <p-dialog header="Search" [(visible)]="display" class="d-block d-lg-none tab-search" *ngIf="display">
                      <div class="col-lg-6">
                        <input type="text" placeholder="Search" [(ngModel)]="search" />
                        <span class="fa fa-search search-icon" (click)="SearchFun(search)"></span>
                      </div>
                    </p-dialog>
                  </div>
                </div>
              </ng-template>
              <ng-template #other_content>
                <div class="col-lg-3 col-md-4">
                  <div class="seg-head-cart" routerLink="/myorder">
                    <img src="../../../assets/images/bag-icon.png" />
                    <span class="badge badge-danger">{{cartCount}}</span>
                  </div>
                  <div class="d-block d-lg-none mob-search-icon" *ngIf="isShowSearch">
                    <span class="fa fa-search" (click)="showDialog()"></span>
                  </div>
                  <div class="search-popup">
                    <p-dialog header="Search" [(visible)]="display" class="d-block d-lg-none tab-search" *ngIf="display">
                      <div class="col-lg-6">
                        <input type="text" placeholder="Search" [(ngModel)]="search" />
                        <span class="fa fa-search search-icon" (click)="SearchFun(search)"></span>
                      </div>
                    </p-dialog>
                  </div>
                </div>
              </ng-template>
              <div class="col-md-4 pl-0 mo-button d-none d-md-block">
                <p-menu #usermenu [popup]="true" [model]="userMenuitems"></p-menu>
                <button type="button" pButton  (click)="usermenu.toggle($event)">
                  {{user}}
                  <i class="fa fa-angle-down"></i>
                </button>

              </div>
              <!--<div class="col-md-4 pl-0 mo-button d-none d-md-block">
                <button type="button" data-toggle="dropdown">
                   {{user}} <i class="fa fa-angle-down"></i>
                  <span class="caret"></span>
                </button>
                <ul class="dropdown-menu" >

                  <li *ngFor="let item of menu" >
                  <a routerLink={{item.routerLink}}>{{item.label}}</a>
                  </li>

                </ul>
              </div>-->
            </div>


          </div>

        </div>
      </div>
    </div>

  </div>
  <div class="order-dialog">
    <p-dialog header="Items will be removed" (onHide)="onCancel()" [(visible)]="showConfirm" [modal]="true" [responsive]="true">
      <p>
        The Items in the cart will be lost permanently.
      </p>
      <p>
        Do you want to continue?
      </p>
      <p-footer class="btn-footer">
        <button type="button" (click)="onConfirm()" label="Yes" class="btn main-btn">Yes</button>
        <button type="button" (click)="onCancel()" label="No" class="btn main-btn">No</button>
      </p-footer>
    </p-dialog>
  </div>
</section>

<section class="seg-top-nav">
  <div class="container-fluid">
    <nav class="navbar navbar-expand-lg navbar-light bg-light p-0">
      <button class="navbar-toggler mob-menu-con p-0 border-0" type="button" (click)="clickEvent()">
        <img src="../../../assets/images/menu-icon.png" class="menu-icon" />
      </button>

      <div class="navbar-collapse offcanvas-collapse" [ngClass]="status ? 'open' : ''">

        <div class="col-12 mob-menu-top">
          <a id="menu_close" (click)="clickEvent()"><i class="fa fa-times"></i></a>
          <div class="mob-user-icon">
            <i class="fa fa-user"></i>
          </div>
          <div class="mob-user-name d-none d-md-block d-lg-none">
            {{username}}
          </div>

          <div class="mob-user-name d-block d-sm-none">
            <div class="mo-button">
              <p-menu #usermenu [popup]="true" [model]="userMenuitems"></p-menu>
              <button type="button" pButton (click)="usermenu.toggle($event)">
                {{username}}
                <i class="fa fa-angle-down"></i>
              </button>
            </div>
          </div>


          <div class="col-md-12 p-0 location-button">
            <i class="fa fa-map-marker"></i>
            <p-dropdown [options]="locationitems" [(ngModel)]="selectedstorelocations" (onChange)="onchange($event)" filter="true"></p-dropdown>
            <i class="fa fa-exclamation-triangle exclamation" aria-hidden="true" *ngIf="!hasAppr" title="No Approver has been assigned.Please contact administrator"></i>

          </div>
        </div>

        <div class="ulitem-lg" *ngIf="!showCarousel">
          <ul class="navbar-nav  mr-auto">
            <li class="nav-item" *ngFor="let menuItem of menuItems; let i = index">
              <a class="nav-link" *ngIf="menuItem.isShow" routerLinkActive="highlited" (click)="navigate(menuItem)" [routerLink]="['/item']" [queryParams]="{ categoryId: menuItem.CategoryId,categoryName:menuItem.CategoryName }" title="{{menuItem.CategoryName}}">  {{menuItem.CategoryName}}</a>
            </li>

            <li class="nav-item d-block d-lg-none" style="border-bottom: 1px #e2e2e2 solid;">
              <div class="col-md-4 pl-0 mo-button ">
                <button type="button" pButton (click)="cmenu.toggle($event)">
                  My Orders <i class="fa fa-angle-down"></i>
                </button>
                <p-menu #cmenu [popup]="true" [model]="myorderitems"></p-menu>
              </div>
            </li>

          </ul>
        </div>

        <!--<p-tabMenu [model]="menuItems" [activeItem]="menuItems[0]"></p-tabMenu>-->
       
        <div class="navbar-slider-section custom-slider-carousal" *ngIf="showCarousel">
          <div class="navbarcarousel">
          <p-carousel [value]="menuItems"
                      [numVisible]="9"
                      [numScroll]="4"
                      [circular]="false"
                      [responsiveOptions]="responsiveOptions">
            <ng-template let-menuItem pTemplate="item">
              <div class="product-item-content">
                <div class="p-mb-3"></div>
                <div>
                  <a class="nav-link" *ngIf="menuItem.isShow" routerLinkActive="highlited" (click)="navigate(menuItem)" [routerLink]="['/item']" [queryParams]="{ categoryId: menuItem.CategoryId,categoryName:menuItem.CategoryName }" title="{{menuItem.CategoryName}}">  {{menuItem.CategoryName}}</a>
                </div>
              </div>
            </ng-template>
          </p-carousel>
          </div>

          <div class="catlist-sm">
            <ul class="navbar-nav mr-auto">
              <li class="nav-item" *ngFor="let menuItem of menuItems; let i = index">
                <a class="nav-link" *ngIf="menuItem.isShow" routerLinkActive="highlited" (click)="navigate(menuItem)" [routerLink]="['/item']" [queryParams]="{ categoryId: menuItem.CategoryId,categoryName:menuItem.CategoryName }" title="{{menuItem.CategoryName}}">  {{menuItem.CategoryName}}</a>
              </li>

              <li class="nav-item d-block " style="border-bottom: 1px #e2e2e2 solid;">
                <div class="col-md-4 pl-0 mo-button ">
                  <button type="button" pButton (click)="cmenu.toggle($event)">
                    My Orders <i class="fa fa-angle-down"></i>
                  </button>
                  <p-menu #cmenu [popup]="true" [model]="myorderitems"></p-menu>
                </div>
              </li>

            </ul>

          </div>

        </div>

        
       
      </div>
    </nav>
  </div>
  <div class="order-dialog">
    <p-dialog header="Items will be removed" [(visible)]="showConfirm" [modal]="true" [responsive]="true">
      <p>
        The Items in the cart will be lost permanently.
      </p>
      <p>
        Do you want to continue?
      </p>
      <p-footer class="btn-footer">
        <button type="button" (click)="onConfirm()" label="Yes" class="btn main-btn">Yes</button>
        <button type="button" (click)="onCancel()" label="No" class="btn main-btn">No</button>
      </p-footer>
    </p-dialog>
  </div>

</section>
